/*globals
    define:false
 */

define([
    'jquery',
    'lodash',
    'helpers/capture_interactions/interaction',
    'jquerypp/class/class'
], /**
 * @param {jQuery} $
 * @param {lodash} _
 * @param {Helpers.CaptureInteractions.Interaction} Interaction
 */
function ($, _, Interaction) {
    'use strict';

    /**
     * @class Helpers.CaptureInteractions.PasteInteraction
     * @extends Helpers.CaptureInteractions.Interaction
     */
    return Interaction.extend(
        'Helpers.CaptureInteractions.PasteInteraction',
        /** @prototype */
        {
            /** @type {String[]} */
            pasteStrings: undefined,

            init: function (event) {
                var self = this;
                this._super('paste', event.target);
                var items = event.clipboardData && event.clipboardData.items;
                if (items) {
                    this.pasteStrings = [];
                    _.toArray(items).forEach(function (item) {
                        item.getAsString(function (string) {
                            self.pasteStrings.push(string);
                        });
                    });
                }
                else {
                    this.pasteStrings = ['Browser does not support extracting paste data.'];
                }

            },

            formatForRaven: function () {
                return $.extend(this._super(), {
                    pasteStrings: this.pasteStrings
                });
            }
        }
    );
});
