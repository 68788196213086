define([
    'jquery', 
    'helpers/namespace/namespace'
], function($, Namespace) {
    'use strict';

    /**
     * @namespace Helpers.GenImageUtil
     */
    return Namespace('Helpers.GenImageUtil', {
        hackInvertColour: function(stringIn) {
            stringIn = stringIn || '';
            var $html;

            try {
                // Must use `$.parseHTML(html)` instead of `$(html)`, or jQuery
                // complains when the HTML string does not strictly begin with
                // "<" (e.g. whitespace).
                $html = $($.parseHTML(stringIn));
            } catch (err) {
                return stringIn;
            }

            var $htmlCopy = $html.clone();

            $html.find('img').each(function(i, imgTag){
                if (imgTag.src.indexOf('ColoredRegion') !== -1 && imgTag.src.indexOf('invert_color') === -1) {
                    if (imgTag.src.indexOf('?') === -1) {
                        $htmlCopy.find('img').eq(i).attr('src', imgTag.src + '?invert_color=1');
                    } else {
                        $htmlCopy.find('img').eq(i).attr('src', imgTag.src + '&invert_color=1');
                    }
                }
            });

            var stringOut = '';
            $.each($.makeArray($htmlCopy), function(i, elm) {
                if (elm.nodeType === Node.TEXT_NODE) {
                    stringOut += elm.textContent;
                }
                else {
                    stringOut += elm.outerHTML || '';
                }
            });

            return stringOut;
        }
    });
});
