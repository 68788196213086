/*global
    App:false,
    define:false
 */

define([
    'jquery',
    'can/control',
    'app/env/env'
], /**
 * @param {jQuery} $
 * @param {can.Control} Control
 */
function ($, Control) {
    'use strict';

    /**
     * Helper to prevent user frustration when accidentally invoking the
     * browser's back function via the backspace key.
     *
     * @class Helpers.PreventBackspaceNavigation
     * @extends can.Control
     */
    var PreventBackspaceNavigation = Control.extend(
        'Helpers.PreventBackspaceNavigation',
        /** @static */
        {
            /** {Helpers.PreventBackspaceNavigation} singleInstance */
            singleInstance: null
        },
        /** @prototype */
        {
            '{window} keydown': function ($elm, event) {
                // If the backspace key was pressed...
                if (event.which === 8) {
                    // ...prevent propagation (into invocation of browser back)
                    // if we suspect the target is not a text editable field.

                    // IE needs to be handled differently because page
                    // navigation occurs on elements besides html and body.
                    // isTextEdit only exists in IE.
                    if (App.Env.IS_IE && !event.target.isTextEdit && !event.target.isContentEditable) {
                        event.preventDefault();
                    }
                    else if ($(event.target).is('body, html')) {
                        event.preventDefault();
                    }
                }
            }
        }
    );

    $(document).ready(function () {
        PreventBackspaceNavigation.singleInstance = PreventBackspaceNavigation.singleInstance ||
                                                    new PreventBackspaceNavigation($('body')[0]);
    });

    return PreventBackspaceNavigation;
});
