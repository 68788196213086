/*globals
    Helpers:false,
    define:false
 */
define([
    'jquery', 
    'jquerypp/controller/controller', 
    'jquerypp/view/ejs/ejs', 
    'bootstrap3/bootstrap3'
], function($) {
    'use strict';

    /**
     * A single-use Bootstrap Modal that renders a given template.
     *
     * @class Widgets.ModalView
     * @extends jQuery.Controller
     */
    $.Controller.extend('Widgets.ModalView',
        /** @static */
        {
            defaults: {
                /**
                 * @type String
                 */
                template: null,
                /**
                 * @type Object
                 */
                templateContext: null,
                acceptSelector: '[data-modal-accept]',
                /**
                 * Cancel is triggered by closing the Bootstrap Modal, which
                 * automatically works with the `data-dismiss` attribute:
                 *
                 *     <a data-dismiss="modal" href="#">Dismiss me</a>
                 */
                /**
                 * @type Function
                 */
                accepted: function() {},
                /**
                 * @type Function
                 */
                canceled: function() {},
                /**
                 * @type Function
                 */
                hidden: function() {},
                /**
                 * @type Function
                 */
                shown: function() {}
            },
            /**
             * Instantiates the controller and binds to the right place in
             * the document body.
             * @returns {Widgets.ModalView}
             */
            addNewInstanceToBody: function(options) {
                var elm = $('<div/>').appendTo($('body'));
                return Widgets.ModalView.newInstance(elm, options);
            }
        },
        /** @prototype */
        {
            _accepted: false,
            _canceled: false,
            /**
             * Constructor
             */
            init: function() {
                this._render();
                this._show();
            },
            accept: function(arg) {
                if (!this._accepted) {
                    this._accepted = true;
                    this.options.accepted.apply(this, [arg]);
                }
                this.dismiss();
            },
            cancel: function(arg) {
                if (!this._canceled) {
                    this._canceled = true;
                    this.options.canceled.apply(this, [arg]);
                }
                this.dismiss();
            },
            dismiss: function() {
                if (!this._accepted && !this._canceled) {
                    this.cancel();
                }
                else {
                    this._hide();
                }
            },
            '{acceptSelector} click': function(elm, event) {
                this.accept(elm);
            },
            /**
             * When focused, prevents keyboard events from reaching the
             * page beneath the modal. See `_focus()`.
             */
            'keyup': function(elm, event) {
                event.stopPropagation();
                return false;
            },
            /**
             * Set focus, to help prevent keyboard events from reaching the
             * page beneath the modal. See also `keyup()`.
             */
            _focus: function() {
                // Set focus to prevent keyboard events from reaching the
                // page beneath the modal.
                this.element.focus();
            },
            _remove: function() {
                if (this.element) {
                    this.element.remove();
                }
            },
            _render: function() {
                var view = $.View(this.options.template).render(this.options.templateContext);
                this.element.html(view);
            },
            _show: function() {
                var self = this;
                var modalElm = this.element.children('.modal');
                modalElm.on('shown.bs.modal', function() {
                    self._focus();
                    self.options.shown.apply(self);
                });
                modalElm.on('hidden.bs.modal', function() {
                    if (!self._accepted && !self._canceled) {
                        self.cancel();
                    }
                    self.options.hidden.apply(self);
                    self._remove();
                });
                modalElm.modal({
                    show: true
                });
            },
            _hide: function() {
                if (this.element) {
                    var modalElm = this.element.children('.modal');
                    modalElm.modal('hide');
                }
            }
        });

});
