/*globals
    App:false,
    define:false
 */
define([
    'can/view/mustache', 
    'app/env/env', 
    'can/map'
], function(can) {
    'use strict';

    /**
     * `{{#condition args...}}content{{/condition}}`
     *
     * Only renders content if the complex condition (JavaScript code) is satisfied.
     * Arguments can be contain any number of JavaScript operators, scope variables and literals.
     * JavaScript operators need to be passed as strings.
     * E.g. {{#condition @index '<=' 2 '||' @index '>' 5}}content{{/condition}}
     */
    can.Mustache.registerHelper('condition', function() {
        var self = this;

        var lastIndex = arguments.length - 1;
        var params = Array.prototype.slice.call(arguments, 0, lastIndex);
        var options = arguments[lastIndex];

        return (function(self, params) {
            'use strict'; // Sandbox this function's scope

            var statement = 'return ';
            for (var i in params) {
                if (params.hasOwnProperty(i)) {
                    statement += can.Mustache.resolve(params[i]);
                }
            }
            statement += ';';
            var bool;
            try {
                bool = Boolean(Function(statement)());
            }
            catch(e) {
                throw new Error('{{#condition}} threw ' + e + ' evaluating "' + statement + '"');
            }
            if (bool) {
                return options.fn(self);
            }
            return options.inverse(self);
        })(self, params);
    });
});
