/*globals
    define:false
 */
define(
    ['can/view/mustache'],
    function(can) {
        'use strict';

        /**
		 * @name compare
         * @example {{#compare left '>=' right}}...{{else}...{{/compare}}
         *
         * Similar to the `{{#if}}` helper, allows comparison of two values
         * via the typical JavaScript operators. Use sparingly, as Mustache
         * templates are intended to be logic-less!
         */
        can.Mustache.registerHelper('compare', function(left, operator, right, options) {

            if (arguments.length < 3) {
                throw new Error("Mustache Helper 'compare' needs 3 arguments");
            }
            if (!operations[operator]) {
                throw new Error("Mustache Helper 'compare' doesn't know the operator " + operator);
            }

            var leftResolved = can.Mustache.resolve(left);
            var rightResolved = can.Mustache.resolve(right);
            var result = operations[operator](leftResolved, rightResolved);
            if (result) {
                return options.fn(this);
            }
            else {
                return options.inverse(this);
            }
        });

        // jshint -W116
        var operations = {
            '==':   function(l, r) { return l == r; },
            '===':  function(l, r) { return l === r; },
            '!=':   function(l, r) { return l != r; },
            '!==':  function(l, r) { return l !== r; },
            '<':    function(l, r) { return l < r; },
            '<=':   function(l, r) { return l <= r; },
            '>':    function(l, r) { return l > r; },
            '>=':   function(l, r) { return l >= r; }
        };
    });
