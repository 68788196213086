/*global
    App:false,
    define:false,
    window:false
 */
define([
    'can/construct',
    'ms-utils/misc/config',
], function(Construct, config) {
    "use strict";

    var userAgent = window.navigator.userAgent;

    // $.browser.msie does not work for IE11 because it reports
    // its user agent differently to previous versions. We instead
    // look for the presence of IE's layout engine.
    var isIEUserAgent = (/trident/i).test(userAgent);

    var isAndroidUserAgent = (/android/i).test(userAgent);
    var isIpadUserAgent = (/iPad/).test(userAgent);
    var isIphoneUserAgent = (/iPhone/).test(userAgent);

    var isWin8TabletUserAgent = (/Touch/).test(userAgent);

    var isAndroidApp = (/MathspaceAND/i).test(userAgent);
    var isIOSApp = (/MathspaceIOS/).test(userAgent);
    var isWin8TabletApp = (/WebView|MSAppHost/).test(userAgent);

    var isOnSmallDevice;
    if (window.screen.height > window.screen.width) {
        // is portrait
        isOnSmallDevice = window.screen.width < 768 &&
                            window.screen.height < 1024;
    } else {
        // is landscape
        isOnSmallDevice = window.screen.width < 1024 &&
                            window.screen.height < 768;
    }
    var iosAppVersion = null;
    if (userAgent.indexOf('MathspaceIOS/') !== -1) {
        iosAppVersion = userAgent.substring(
        userAgent.indexOf('MathspaceIOS/')).split('/')[1];
        iosAppVersion = parseFloat(iosAppVersion);
    }



    /**
     * Static singleton that stores details about the application
     * environment.
     *
     * @class App.Env
     * @extends can.Construct
     */
    Construct.extend('App.Env',
        /** @static */
        {
            IS_IE: isIEUserAgent,
            /**
             * ios app version, eg. 2.0
             */
            IOS_APP_VERSION: iosAppVersion,
            /**
             * Whether the device viewing the application is a device
             * running our Android application.
             *
             * @type Boolean
             */
            DEVICE_IS_ANDROID_APP: isAndroidApp,
            /**
             * True when the device is an Android tablet.
             *
             * @type Boolean
             */
            DEVICE_IS_ANDROID_TABLET: isAndroidUserAgent,
            /**
             * Whether the device viewing the application is an iPad
             * running our iOS application.
             *
             * @type Boolean
             */
            DEVICE_IS_IPAD_APP: isIOSApp,
            /**
             * True when the device is an iPad.
             *
             * @type Boolean
             */
            DEVICE_IS_IPAD_TABLET: isIpadUserAgent,
            /**
             * True when the device is an iPhone.
             *
             * @type Boolean
             */
            DEVICE_IS_IPHONE: isIphoneUserAgent,
            /**
             * Whether the device viewing the application is a Windows 8
             * tablet running our application.
             *
             * @type Boolean
             */
            DEVICE_IS_WIN8_APP: isWin8TabletApp,
            /**
             * True when the device is a Win8 Tablet.
             *
             * @type Boolean
             */
            DEVICE_IS_WIN8_TABLET: isWin8TabletUserAgent,
            /**
             * Whether the device viewing the application is a tablet device
             * - whether through a web-browser OR our native app.
             *
             * @type Boolean
             */
            DEVICE_IS_TABLET: isAndroidUserAgent || isIpadUserAgent || isWin8TabletUserAgent,
            /**
             * Whether the device viewing the application is a tablet device
             * running our native client application.
             *
             * @type Boolean
             */
            DEVICE_IS_TABLET_APP: isAndroidApp || isIOSApp || isWin8TabletApp,
            /**
             * Detect whether the device is considered on mobile device, they have
             * screen resolution lower than 1280 x 800
             *
             * @type Boolean
             */
            DEVICE_IS_SMALL_DEVICE: isOnSmallDevice,
            /**
             * Version of the site - both backend and frontend.
             *
             * @type String
             */
            SITE_VERSION: '',
            /**
             * Minimum version of the frontend that the backend requires.
             *
             * @type String
             */
            SITE_VERSION_MIN: '',
            /**
             * The root URL of the teach app
             * @type String
             */
            TEACH_URL: '/teach2/',
            /**
             * Whether the application was launched in StealJS production
             * mode.
             *
             * @type Boolean
             */
            PRODUCTION: false,
            /**
             * Country detected from request.
             *
             * @type String - 2-char country code.
             */
            REQUEST_COUNTRY: '',
            /**
             * Same as Django's setting STATIC_URL; the base URL to where
             * static assets are located.
             * Must end with a slash.
             *
             * @type String
             */
            STATIC_URL: '/static/',
            CONTENT_TYPES: {
                /**
                 * The contentTypeId for curriculums
                 *
                 * @type Number
                 */
                CURRICULUM: 27,
                /**
                 * The contentTypeId for topics
                 *
                 * @type Number
                 */
                TOPIC: 11,
                /**
                 * The contentTypeId for subtopics
                 *
                 * @type Number
                 */
                SUBTOPIC: 12,
                /**
                 * The contentTypeId for ProblemTemplate
                 *
                 * @type Number
                 */
                PROBLEM_TEMPLATE: 13,
                /**
                 * The contentTypeId for adaptive tasks
                 *
                 * @type Number
                 */
                ADAPTIVE_TASK: 182,
                /**
                 * The contentTypeId for custom subtopic tasks
                 *
                 * @type Number
                 */
                SUBTOPIC_TASK: 38,
                /**
                 * The contentTypeId for custom topic tasks
                 *
                 * @type Number
                 */
                TOPIC_TASK: 190,
                /**
                 * The contentTypeId for custom curriculum tasks
                 *
                 * @type Number
                 */
                CURRICULUM_TASK: 192,
                /**
                 * The contentTypeId for adaptive tasks
                 *
                 * @type Number
                 */
                ADAPTIVE_WORKOUT: 172,
                /**
                 * The contentTypeId for adaptive tasks
                 *
                 * @type Number
                 */
                DEBUG_WORKOUT: 188,
                /**
                 * The contentTypeId for FastTrackWorkout.
                 */
                FASTTRACK_WORKOUT: 203,
                /**
                 * The contentTypeId for FastTrackTask.
                 */
                FASTTRACK_TASK: 213,
                /**
                 * The contentTypeId for custom subtopic tasks
                 *
                 * @type Number
                 */
                SUBTOPIC_WORKOUT: 104,
                /**
                 * The contentTypeId for custom topic tasks
                 *
                 * @type Number
                 */
                TOPIC_WORKOUT: 194,
                /**
                 * The contentTypeId for custom curriculum tasks
                 *
                 * @type Number
                 */
                CURRICULUM_WORKOUT: 195
            },
            /**
             * The signup page.
             *
             * @type String
             */
            SIGNUP_URL: '/signup/',
            /**
             * The login page. This will redirect to the student or teacher
             * dashboard if the user is already logged in.
             *
             * @type String
             */
            LOGIN_URL: '/accounts/login/',
            /**
             * @type String
             */
            TOS_URL: config.tosUrl,
            /**
             * @type String
             */
            PRIVACY_POLICY_URL: config.privacyPolicyUrl,
            /**
             * ID of the logged-in User. Zero if not logged in.
             *
             * @type Number
             */
            USER_ID: 0,
            /**
             * Data source of the logged-in User.
             * 0 = MANUAL if not logged in.
             *
             * @type Number
             */
            USER_DATA_SOURCE: 0,
            /**
             * Email of the logged-in User. Empty string if not logged in.
             *
             * @type String
             */
            USER_EMAIL: '',
            /**
             * Corresponds to our preferred format in Django's DATE_INPUT_FORMATS.
             */
            DATETIME_FORMAT: {
                MOMENT_JS: 'MMM D, h:mm a',
                DATETIME_PICKER: 'H:ii p DD d MM yyyy'
            },
            isPearsonUSUser: function() {
                return this.USER_DATA_SOURCE === 4;
            },
            /**
             * Updates App.Env with key/values from a given hashmap.
             * @param {Object} newSettings
             */
            updateWith: function(newSettings) {
                for (var key in newSettings) {
                    if (newSettings.hasOwnProperty(key)) {
                        App.Env[key] = newSettings[key];
                    }
                }
            },
            /**
             * Look for an object literal populated by our
             * ``include_javascriptmvc`` Django templatetag.
             */
            updateWithDjangoSettings: function() {
                if (typeof(window.APP_ENV_INIT) !== 'undefined') {
                    App.Env.updateWith(window.APP_ENV_INIT);
                }
            }
        },
        /** @prototype */
        {});


    // We can do this immediately instead of on a ready handler, because
    // the script tag must have been executed if we're here.
    App.Env.updateWithDjangoSettings();

});
