define(['jquery', 'jquery-plugins/waypoints/waypoints'], function($) {
	"use strict";

    // TODO: Replace instances of jQuery with $, then remove this shim.
    var jQuery = $;

	$.fn.aetherNavigation = function(){

        var container = this;
		var navigationButton = container.find('.nav-button'),
			navigation = container.find('.navigation'),
			navigationHeight = container.find('.logo').height();

		var toggleNavigationVisibility = function () {
			// Since the window width is not always the same as the width used
			// in media queries (e.g. the window has a scrollbar) we toggle
			// the navigation visibility based on the visibility of the hamburger icon
			if (navigationButton.is(':hidden')) {
				navigation.addClass('desktop').removeClass('mobile');
			} else {
				navigation.addClass('mobile').removeClass('desktop');
			}
		};

		jQuery('#main-content section:first-child').css({
			"margin-top" : navigationHeight + 30 + "px"
		});

		var sections = jQuery('section');
		var navigationLinks = navigation.find('a');
		sections.waypoint({
		handler: function(direction) {
			var active_section;
			active_section = jQuery(this);
			if (direction === "up") active_section = active_section.prev();
			var active_link = jQuery('nav a[href="#' + active_section.attr("id") + '"]');
			navigationLinks.removeClass("active");
			active_link.addClass("active");
		},
		offset: '10%'
		});

		toggleNavigationVisibility();

		navigationButton.click(function(){
			if(navigation.is(':hidden')) {
				navigation.slideDown();
			} else {
				navigation.slideUp();
			}
		});

		navigationLinks.click(function(){
			if(navigation.is(':visible') && navigation.hasClass('mobile')) {
				navigation.slideUp();
			}
		});

		jQuery(window).resize(function() {
			toggleNavigationVisibility();
		});
	};
});