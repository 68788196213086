/*global
    define:false
 */
define([
    'helpers/ajax_csrf/ajax_csrf', // For supplying Django's CSRF token
    'helpers/ajax_django/ajax_django', // Shim to fix POSTing nested data to Django.
    'helpers/ajax_no_cache/ajax_no_cache', // Disable IE's AJAX caching on $.Model calls
    'helpers/ajax_retry/ajax_retry', // Automatic retry on AJAX requests.
    'helpers/ajax_auth_manager/ajax_auth_manager',
    'helpers/ajax_version_manager/ajax_version_manager',
    // TODO: Disabled fastclick because it causes some components to no longer
    //       react to touches.
    'helpers/prevent_backspace_navigation/prevent_backspace_navigation',
    'helpers/capture_interactions/capture_interactions', // For capturing user interactions to diagnose bugs
    // To disable the cookie_law helper.
    // 'helpers/cookie_law', // For compliance with EU cookie regulation.
    'helpers/dbl_click_shield/dbl_click_shield', // Stop duplicate events from accidental double
                                                 // clicks.
    'helpers/mustache/mustache',
    // TODO: This is not the right place for this helper, but having it at a higher
    // level may cause StealJS issues on some browsers.
    'helpers/url/url',
    // We must have JSBridge bindings loaded and present on all pages,
    // in case the iOS app is running, since it is going to send messages over
    // it. If not present, we get really weird and hard to debug error messages.
    'mobile/jsbridge/jsbridge',
    'mobile/native/native',
    'app/footer/footer',
    'app/tooltips/tooltips',
    // Render the white topbar.
    'jquery-plugins/navigation/navigation',
    // Applies a fade out transition when navigating between pages using hyperlink.
    'app/transition_links/transition_links',
], function() {
});
