/*globals
    define:false
 */
define(
    ['can/view/mustache'],
    function(can) {
        'use strict';

        /**
         * @name setVariable
         * @example {{setVariable "user_id" user.id}}`
         *
         * Sets a value on the current scope of the view data. The new value
         * can then be referenced using the new name within the same template.
         *
         * You can use this to disambiguate, for example, `id` when there are
         * different items with an `id` value.
         *
         * Use sparingly, as Mustache templates are intended to be logic-less!
         */
        can.Mustache.registerHelper('setVariable', function(key, value) {

            var valueResolved = can.Mustache.resolve(value);
            this.attr(key, valueResolved);
        });
    });
