/*globals
 App,
 define,
 */
define([
    'can/construct',
    'app/env/env',
    'raven-js'
], function (Construct, Env, Raven) {
    'use strict';

    /**
     * @class Helpers.Url
     * @extends can.Construct
     */
    Construct.extend('Helpers.Url',
        /* @static */
        {
            _routeMap: {},

            /**
             * Allows us to specify a route map which will be used by
             * the Url helper to map route names to URLs.
             * @param {Object} routeMap An object which holds mappings from
             * route/url names to full URLs. Sample routeMap:
             * {
             *     'error': {
             *         url: 'error/',
             *         controller: Study2.ErrorPage,
             *         pageTitle: ' :: Error'
             *     },
             *
             *     'study:subtopic_adaptive_workout': {
             *         url: 'curriculum/:curriculumId/topic/:topicId/subtopic/:subtopicId/adaptiveworkout/',
             *         controller: 'Study2.AllTopicsSubtopic',
             *         pageTitle: ' :: Subtopic adaptive workout'
             *     },
             * }
             * By default, the url property maps to an SPA (hashbang) url.
             * To refer to a base URL, you should use the baseUrl property instead.
             */
            setRouteMap: function (routeMap) {
                this._routeMap = routeMap;
            },

            /**
             * Returns a URL based on a pageName and some URL parameters.
             * Example:
             * Helpers.Url.get('study:curriculum_viewer', 123, 456)
             * Returns: #!curriculum/123/topic/456/
             * @param {String} pageName The name of the route to
             * @param {String} ...args Arguments to render in the URL
             */
            get: function () {
                var urlData = this._routeNameToURL.apply(this, arguments);
                var url = urlData.url;

                if (urlData.isBaseUrl) {
                    return url;
                }

                return '#!' + (url || '');
            },

            _routeNameToURL: function (pageName) {
                var params;

                // Check if helper is called from inside Mustache template or from custom code
                if (arguments[arguments.length - 1] instanceof Object) {
                    params = Array.prototype.slice.call(arguments, 1, arguments.length - 1);
                }
                else {
                    params = Array.prototype.slice.call(arguments, 1, arguments.length);
                }

                var url;
                var page = this._routeMap[pageName];
                var isBaseUrl = false;
                if (page) {
                    url = page.baseUrl || page.url;
                    isBaseUrl = !!page.baseUrl;
                }
                // Empty string URL hashbang is allowed
                if (url === null) {
                    var errorUrl = this._routeMap.error;

                    var pathRegex = /[\/\\]/;
                    // If it contains a path, then the pageName is the URL
                    if (pathRegex.test(pageName)) {
                        return {url: pageName, isBaseUrl: isBaseUrl};
                    }

                    var errorMessage = 'URL helper: Page name "' + pageName + '" does not map to a valid URL';

                    if (App.Env.PRODUCTION) {
                        Raven.captureMessage(errorMessage);
                    }
                    else {
                        throw new Error(errorMessage);
                    }
                    return {url: errorUrl, isBaseUrl: isBaseUrl};
                }
                url = url.replace(/:[^\/]+/g, function () {
                    var param = params.shift();
                    if (param instanceof Function) {
                        param = param();
                    }
                    return param || (params.length ? '0' : '');
                });
                return {url: url.replace(/\/{2,}/g, '/'), isBaseUrl: isBaseUrl};
            }
        },
        /* @prototype */
        {}
    );
});
