/*globals
    define:false
 */
define([
    'jquery',
    'can/view/mustache'
], function($, can) {
    'use strict';

    /**
     * Return the css class for displaying a mark.
     * Corresponds to the ``bandify`` template tag.
     *
     * @param {Number} percent Number
     * @return {String} css class name for the percentage number,
     *         empty string if the percent cannot be parsed as a number
     */
    can.Mustache.registerHelper('bandify', function (number, options) {
        var numberResolved = can.Mustache.resolve(number);

        var low = 50,
            high = 75;

        if (!$.isNumeric(numberResolved)) {
            return 'label-default';
        }
        var value = parseFloat(numberResolved);

        if (value < low) {
            return 'label-danger';
        } else if (value >= high) {
            return 'label-success';
        } else {
            return 'label-warning';
        }
    });

});
