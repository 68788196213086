/*globals
    App:false,
    define:false,
    Mobile:false
 */
define([
    'jquery', 
    'app/env/env', 
    'jquerypp/controller/controller'
], function($) {
    'use strict';
    
    /**
     * Controller that delivers tweaks to iPad, tablets and mobiles, in
     * order to provide a more native-feeling UX.
     *
     * @class Mobile.Native
     * @extends jQuery.Controller
     */
    $.Controller.extend('Mobile.Native',
        /** @static */
        {
            defaults: {
                'fixFixedClassName': 'fix-fixed'
            }
        },
        /** @prototype */
        {
            /**
             * Constructor
             */
            init: function() {
                this.disableIosTouchSelections();
                this.disableWin8Zoom();

                if (App.Env.DEVICE_IS_IPAD_APP) {
                    this.fixFixedElement();
                }
            },
            /**
             * Prevents the user from being able to touch-select text/elements, which
             * typically gets in the way of our desired interactions.
             *
             * Source: http://stackoverflow.com/questions/5995210/disabling-user-selection-in-uiwebview
             */
            disableIosTouchSelections: function() {
                var documentStyle = window.document.documentElement.style;
                documentStyle.webkitUserSelect = 'none';
                documentStyle.webkitTouchCallout = 'none';
            },
            /**
             * Prevents the user from being able to trigger zooming
             * (the pinch gesture).
             *
             * Source: http://msdn.microsoft.com/en-us/library/ie/hh920761(v=vs.85).aspx
             */
            disableWin8Zoom: function() {
                var documentStyle = window.document.documentElement.style;
                documentStyle.msTouchAction = 'pan-x pan-y';
            },
            /**
             *
             */
            fixFixedElement: function() {
                var self = this;

                this.element.on('focus', 'input, textarea, select', function(e) {
                    self.element.addClass(self.options.fixFixedClassName);
                })
                .on('blur', 'input, textarea, select', function(e) {
                    self.element.removeClass(self.options.fixFixedClassName);

                    // Fix for some scenarios where you need to start scrolling
                    setTimeout(function() {
                        $(document).scrollTop($(document).scrollTop());
                    }, 20);
                });
            }
        });


    // Auto-load when the page is ready AND the device is a tablet.
    $(document).ready(function() {
        if (App.Env.DEVICE_IS_TABLET) {
            Mobile.Native.newInstance($('body'));
        }
    });

});
