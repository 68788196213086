/*globals
	define:false
 */
define([
    'jquery',
    'can/util/jquery',
    'helpers/string_util/string_util',
    'mustache!ui_components/modal_video/modal_video.mustache',
    'helpers/mustache/compare',
    'jquerypp/controller/controller',
    'jquery-plugins/dimensions/dimensions',
    'can/map/delegate',
    'ui_components/wistia_video/wistia_video',
    'ui_components/youtube_video/youtube_video',
    'can/component',
    'can/map/define'
], function($, can, StringUtil, template) {
    'use strict';

    var MIN_OVERLAY_MARGIN = 30;
    var MAX_4_3_VIDEO_SIZE = { width: 960, height: 720 };
    var MAX_16_9_VIDEO_SIZE = { width: 1280, height: 720 };

    /**
     * Modal dialog.
     *
     * @name uc-modal-video
     * @example <uc-modal-video title="{title}"></uc-modal-video>
     */
    can.Component.extend('UIComponents.ModalVideo',
        {
            /**
             * Convenience method to render a modal using a template.
             *
             * @param template
             * @param context
             * @param container
             * @returns jQuery.Deferred
             */
            renderModal: function(template, context, container) {
                container = container || document.body;
                var frag = template(context);
                $(container).append(frag);
                return $(container).find('uc-modal-video').scope('modalDeferred');
            }
        },
        /* @prototype */
        {
            tag: 'uc-modal-video',
            template: template,
            scope: {
                define: {
                    title: {
                        type: 'string',
                        value: ''
                    },
                    modalShown: {
                        type: 'boolean',
                        value: true
                    },
                    windowSize: {
                        type: 'object',
                        value: $(window).dimensions()
                    },
                    headerHeight: {
                        type: 'number',
                        value: 63
                    },
                    aspect169: {
                        type: 'boolean',
                        value: true
                    },
                    heightRatio: {
                        type: 'number',
                        value: null
                    },
                    modalDeferred: {
                        value: $.Deferred
                    },
                    keepOpen: {
                        type: 'boolean',
                        value: false
                    }
                },
                getDialogSize: function () {
                    var scope = this.attr();
                    var availableVideoSpace = {
                        width: scope.windowSize.width - MIN_OVERLAY_MARGIN * 2,
                        height: scope.windowSize.height - scope.headerHeight - MIN_OVERLAY_MARGIN * 2
                    };
                    var maxVideoSize = scope.aspect169 ? MAX_16_9_VIDEO_SIZE : MAX_4_3_VIDEO_SIZE;
                    var videoScale = Math.min(1,
                        availableVideoSpace.width / maxVideoSize.width,
                        availableVideoSpace.height / maxVideoSize.height);
                    var videoSize = { width: maxVideoSize.width * videoScale, height: maxVideoSize.height * videoScale };

                    var heightRatio = scope.customHeightRatio;
                    var dialogHeight;

                    if (heightRatio) {
                        dialogHeight = videoSize.width * heightRatio;
                    } else {
                        dialogHeight = videoSize.height + scope.headerHeight;
                    }

                    return { width: videoSize.width, height: dialogHeight };
                },
                closeModal: function(item, elm) {
                    var modalComponent = elm.closest('uc-modal-video');
                    var modalElm = modalComponent.find('.modal');
                    // TODO: Could do proper "on transition end" like in Bootstrap.
                    modalElm.removeClass('in');
                    setTimeout(function() {
                        modalComponent.remove();
                    }, 300);
                    this.modalDeferred.resolve();
                }
            },
            events: {
                '{window} resize': function () { this.handleWindowSizeChange(); },
                '{window} orientationchange': function () { this.handleWindowSizeChange(); },
                handleWindowSizeChange: function () {
                    this.scope.attr('windowSize', $(window).dimensions());
                    if (this.scope.attr('headerHeight') !== 0) {
                        this.scope.attr('headerHeight', this.element.find('.modal-header').outerHeight());
                    }
                },
                '{scope} modalShown change': function() {
                    if (!this.scope.attr('modalShown')) {
                        this.scope.closeModal(this.scope, this.element);
                    }
                },
                inserted: function() {
                    var modalElm = this.element.find('.modal');
                    // From Bootstrap's modal.js:
                    modalElm.addClass('in');
                    $(document.body).addClass('modal-open');
                    modalElm.show();
                    this.handleWindowSizeChange();

                },
                removed: function() {
                    $(document.body).removeClass('modal-open');
                },
                // Clicking the backdrop should close the modal.
                'click': function(elm, event) {
                    if ($(event.target).is('.modal')) {
                        this.scope.closeModal(this.scope, this.element);
                    }
                },
                // Clicking the "X" should close the modal.
                'button.close click': function() {
                    this.scope.closeModal(this.scope, this.element);
                },
                'uc-wistia-video wistia-end': function (elem, event) {
                    if (!this.scope.attr('keepOpen') && !elem.control().scope.isPlayingInLoop()) {
                        this.scope.closeModal(this.scope, this.element);
                    }
                }
            },
            helpers: {
                modalDialogStyle: function () {
                    var scope = this.attr();
                    var dialogSize = this.getDialogSize();
                    return StringUtil.format(
                        'width: {0}px; height: {1}px; margin-left: {2}px; margin-top: {3}px;',
                        dialogSize.width,
                        dialogSize.height,
                        (scope.windowSize.width - dialogSize.width) / 2,
                        (scope.windowSize.height - dialogSize.height) / 2);
                },
                modalContentStyle: function () {
                    var dialogSize = this.getDialogSize();
                    return StringUtil.format(
                        'width: {0}px; height: {1}px;',
                        dialogSize.width,
                        dialogSize.height);
                }
            }
        });
});
