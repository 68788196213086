/*global
    define:false,
    Widgets:false
*/
define([
    'jquery', 
    'helpers/capture_interactions/interaction',
    'jquerypp/class/class',
    'widgets/math_input/math_input'
], /**
 * @param {jQuery} $
 * @param {Helpers.CaptureInteractions.Interaction} Interaction
 */
function ($, Interaction) {
    'use strict';

    /**
     * @class Helpers.CaptureInteractions.KeyboardInteraction
     * @extends Helpers.CaptureInteractions.Interaction
     */
    return Interaction.extend(
        'Helpers.CaptureInteractions.KeyboardInteraction',
        /** @prototype */
        {
            /** @type {String} textEntered */
            textEntered: undefined,

            init: function (event) {
                this._super('keyboard', event.target);
                this.textEntered = '';
                this.append(event);
            },

            append: function (event) {
                this.timestamp = new Date();
                var keyCodeMappings = {
                    '37': 8592,  // left arrow
                    '38': 8593,  // up arrow
                    '39': 8594,  // right arrow
                    '40': 8595,  // down arrow
                    '13': 8629,  // enter
                    '16': 8679,  // shift
                    '8': 9003,   // backspace
                    '46': 10007, // delete
                    '20': 8682,  // caps lock
                    '107': 43,   // add
                    '109': 45,   // subtract
                    '111': 47,   // divide
                    '106': 42   // multiply
                };
                this.textEntered += String.fromCharCode(keyCodeMappings[event.keyCode] || event.keyCode);
            },

            formatForRaven: function () {
                var $mathquillElement = $(this.element).parents().addBack().filter('.mq-math-mode').last();
                var text;
                if ($mathquillElement.length === 1 &&
                    $.isFunction($mathquillElement.control) &&
                    $mathquillElement.control(Widgets.MathDisplay)) {
                    text = $mathquillElement.control(Widgets.MathDisplay).latex();
                } else {
                    text = $(this.element).text();
                }
                return $.extend(this._super(), {
                    textEntered: this.textEntered,
                    completeText: text
                });
            }
        }
    );
});
