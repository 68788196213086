/*global
    App:false,
    document:false,
    define:false
 */
define([
    'jquery', 
    'bootstrap3/bootstrap3', 
    'app/env/env', 
    'jquerypp/controller/controller'
], function($) {
    "use strict";

    /**
     * Globally renders tooltips on any tags that have the correct `rel`
     * attribute. By declaring here we can ensure consistent application of
     * the tooltip code.
     *
     *  <span rel="tooltip" title="Note about pork chop sandwiches">
     *      Pork chop sandwiches
     *  </span>
     *
     *  @class App.TooltipWatcher
     *  @extend jQuery.Controller
     */
    $.Controller.extend('App.TooltipWatcher',
        /** @static */
        {
            defaults: {
                /**
                 * The event that will trigger the tooltip
                 */
                triggerEvent: null
            }
        },
        /** @prototype */
        {
            /**
             * The jQuery reference to current showing tooltip
             * @type {jQuery}
             */
            _showingTooltip: null,
            '[rel=tooltip] {triggerEvent}': function(elm, event) {
                if (this._isActionableElement(elm) && App.Env.DEVICE_IS_TABLET) {
                    // This is mainly for solver pages
                    // After user tap on hint button, the button is removed
                    // the tooltip will just be there for 1 second and then
                    // removed, creates bad UX.
                    return;
                }
                if (this._showingTooltip) {
                    this.hideShowingTooltip();
                    if (this._showingTooltip[0] === elm[0]) {
                        // So if user clicked on the one that's showing,
                        // just hide it.
                        this._showingTooltip = null;
                    } else {
                        this._showingTooltip = $(event.currentTarget);
                        this.show(this._showingTooltip);
                    }
                } else {
                    this._showingTooltip = $(event.currentTarget);
                    this.show(this._showingTooltip);
                }
            },
            '.tooltip mouseleave': function(elm, event) {
                this.mouseleaveHandler(elm, event);
            },
            '[rel=tooltip] mouseleave': function(elm, event) {
                this.mouseleaveHandler(elm, event);
            },
            mouseleaveHandler: function (elm, event) {
                // Don't hide the tooltip if the mouse is hovering the tooltip.
                var $newTarget = $(event.toElement || event.relatedTarget);
                if ($newTarget.parents().addBack().is('.tooltip')) {
                    return;
                }

                this.hideShowingTooltip();
                this._showingTooltip = null;
            },
            hideShowingTooltip: function() {
                if (this._showingTooltip) {
                    this.hide(this._showingTooltip);
                }
            },
            show: function (elem) {
                // Check if created already.
                var controller = elem.control(App.Tooltip);
                if (!controller) {
                    App.Tooltip.newInstance(elem);
                }
            },
            hide: function (elem) {
                // Check if created.
                var controller = elem.control(App.Tooltip);

                if (controller) {
                    controller.destroy();
                }
            },
            /**
             * If the tooltip is installed on actionable element (buttons)
             * then we do not show the tooltip
             * @param {jQuery} element
             * @returns {boolean}
             * @private
             */
            _isActionableElement: function(element) {
                var tagName = element.prop('tagName');
                var actionableTags = [
                    'A',
                    'BUTTON'
                ];
                return actionableTags.indexOf(tagName) !== -1;
            }
        });

    /**
     * Renders a Bootstrap tooltip on an element. The main reason we use
     * a Controller is because we can capture when the originating element
     * is removed and cleanup the tooltip properly. Otherwise some cases
     * leave behind "stuck" tooltips (See Trello #3107 w.r.t. the tooltip
     * on the Hint button getting stuck after clicking).
     *
     * @class App.Tooltip
     * @extend jQuery.Controller
     */
    $.Controller.extend('App.Tooltip',
        /** @static */
        {},
        /** @prototype */
        {
            /**
             * Initialise Boostrap tooltip on our originating element,
             * and show it immediately.
             */
            init: function() {
                this.element.tooltip({
                    container: 'body',
                    trigger: 'manual'
                }).tooltip('show');
            },
            /**
             * Destroy the Bootstrap tooltip when the originating element
             * is removed.
             */
            destroy: function() {
                if (!this.destroyed) {
                    this.element.tooltip('hide');
                    // We need to delete the internal data
                    // from bootstrap tooltip
                    this.element.removeData('bs.tooltip');
                    this._super();
                    this.destroyed = true;
                }
            }
        });

    $(document).ready(function() {

        App.TooltipWatcher.newInstance(document.body, {
            triggerEvent: App.Env.DEVICE_IS_TABLET ? 'click' : 'mouseenter'
        });
    });
});
