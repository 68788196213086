/*globals
    define:false
 */
define([
    'jquery', 
    'mustache!helpers/ajax_version_manager/views/outdated_version_modal.mustache', 
    'app/env/env', 
    'can/view/mustache', 
    'widgets/modal_view/modal_view'
], function($) {
    'use strict';

    var isModalOpen = false;

    $.ajaxPrefilter(function(options, originalOptions, jqXHR) {
        jqXHR.done(function() {
            // Display the update modal when the Site-Version-Min header
            // differs.
            var backendVersion = jqXHR.getResponseHeader('Site-Version-Min');
            if (backendVersion && backendVersion != App.Env.SITE_VERSION_MIN && !isModalOpen) {
                isModalOpen = true;

                Widgets.ModalView.addNewInstanceToBody({
                    template: 'helpers/ajax_version_manager/views/outdated_version_modal.mustache',
                    templateContext: {},
                    accepted: function(elm) {
                        isModalOpen = false;
                        location.reload();
                    },
                    canceled: function() {
                        isModalOpen = false;
                    }
                });
            }
        });
    });
});
