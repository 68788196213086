/*globals
    App:false,
    define:false
 */
define([
    'can/view/mustache', 
    'app/env/env'
], function(can) {
    'use strict';

    /**
     * `{{math_round value}}`
     *
     * Rounds a value to nearest integer.
     */
    can.Mustache.registerHelper('math_round', function(value) {
        return String(Math.round(can.Mustache.resolve(value)));
    });
});
