/*globals
    define:false
 */

define([
    'jquery',
    'can/view/mustache',
    'helpers/i18n/i18n'
], function($, can) {
        'use strict';

        /**
         * Convert seconds into human readable format like:
         * xx hours xx minutes, or xx minutes
         *
         * @param {Number} totalSeconds
         * @param {String} format long, short or micro
         * @return {String}
         */
        can.Mustache.registerHelper('timedelta_format', function(totalSeconds, format) {
            totalSeconds = can.Mustache.resolve(totalSeconds);
            format = can.Mustache.resolve(format);
            
            var DEFAULT = '-',
                formatMaps = {
                    "long": {
                        'hour': gettext('hour'),
                        'hours': gettext('hours'),
                        'minute': gettext('minute'),
                        'minutes': gettext('minutes')
                    },
                    "short": {
                        'hour': gettext('hr'),
                        'hours': gettext('hrs'),
                        'minute': gettext('min'),
                        'minutes': gettext('mins')
                    },
                    "micro": {
                        'hour': gettext('hr'),
                        'hours': gettext('hr'),
                        'minute': gettext('m'),
                        'minutes': gettext('m')
                    }
                };

            if(!$.isNumeric(totalSeconds)) {
                return DEFAULT;
            }
            format = format || 'long';

            var strings = formatMaps[format],
                hours = parseInt((totalSeconds / 3600) % 24, 10),
                minutes = parseInt((totalSeconds / 60) % 60, 10),
                seconds = parseInt(totalSeconds % 60, 10);

            if (seconds) {
                // Round seconds to one minute.
                minutes += 1;
            }

            var hourText = ngettext(strings.hour, strings.hours, hours),
                minuteText = ngettext(strings.minute, strings.minutes, minutes);

            if (format === "micro") {
                // Process micro format eg.. 1.2 hrs, 1 hr, 36.4m
                if (hours > 0) {
                    return (Math.round(totalSeconds/3600 * 10) / 10).toString() + hourText;
                }
                else {
                    return minutes.toString() + minuteText;
                }

            } else {
                // Process short and long format
                var resultString = [];

                if (hours > 0) {
                    resultString.push(hours + ' ' + hourText);
                }
                if (minutes > 0) {
                    resultString.push(minutes + ' ' + minuteText);
                }
                return resultString.join(" ");
            }
        });
    });
