/*globals
    App:false,
    define:false
 */
define([
    'can/view/mustache', 
    'app/env/env'
], function(can) {
    'use strict';

    /**
     * @name static
     * @example {{static "img/logo.jpg"}} -> /static/img/logo.jpg
     *
     * Similar to the Django `static` templatetag, prepends with the
     * absolute URL to the static folder.
     */
    can.Mustache.registerHelper('mpa_url', function(url) {
        url = can.Mustache.resolve(url);
        return '/' + url.replace('#!', '');
    });
});
