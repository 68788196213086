define([
    'jquery', 
    'helpers/capture_interactions/interaction',
    'jquerypp/class/class'
], /**
 * @param {jQuery} $
 * @param {Helpers.CaptureInteractions.Interaction} Interaction
 */
function ($, Interaction) {
    'use strict';

    /**
     * @class Helpers.CaptureInteractions.MouseOrTouchInteraction
     * @extends Helpers.CaptureInteractions.Interaction
     */
    return Interaction.extend(
        'Helpers.CaptureInteractions.MouseOrTouchInteraction',
        /** @prototype */
        {
            init: function (type, event) {
                this._super(type, event.target);
            }
        }
    );
});
