// Provides backwards compatibility for momentjs.
define(['moment', 'moment-timezone'], function(moment) {

    'use strict';

    window.moment = moment;

    return moment;

});
