/*globals
    document:false,
    define:false
 */
define([
    'jquery', 
    './resources/navigation'
], function($) {
    'use strict';

    $(document).ready(function() {
        var topbarElm = $('.topbar-anonymous');
        if (topbarElm.length) {
            topbarElm.aetherNavigation();
        }
    });
});
