/*globals
    App:false,
    clearTimeout:false,
    Mobile:false,
    JSBridgeObj:false,
    setTimeout:false,
    define:false,
    window:false
*/
define([
    'jquery',
    'ms-utils/app-logging',
    'jquerypp/controller/controller',
    'jquerypp/lang/json/json',
    './resources/JSBridge',
    'app/env/env'
], function($, appLogging) {
    'use strict';

    var Logger = appLogging.default;

    /**
     * For message passing between Javascript and a wrapping iOS app that
     * uses a UIWebView.
     *
     * Since the JSBridge protocol works through the global namespace,
     * this is implemented as a singleton.
     *
     * Makes use of: http://code.google.com/p/jsbridge-to-cocoa/
     *
     *
     * @class Mobile.JSBridge
     * @extends jQuery.Controller
     */
    // TODO: Refactor this; there is an interface and different implementations
    $.Controller.extend('Mobile.JSBridge',
        /** @static */
        {
            _instance: null,
            JSBridgeObj: JSBridgeObj,
            /**
             * Button constants.
             */
            buttonCancel: "Cancel",
            buttonEnter: "Enter",
            buttonHide: "Hide",
            buttonSkip: "Skip",
            buttonHint: "Hint",
            buttonHint2: "Hint2",
            buttonNext: "Next",
            buttonNextStep: "NextStep",
            buttonPrev: "Prev",
            buttonVideo: "Video",
            /**
             * Returns the singleton Mobile.JSBridge instance.
             *
             * @return {Mobile.JSBridge}
             */
            getInstance: function() {
                if (!Mobile.JSBridge._instance) {
                    Mobile.JSBridge._instance = Mobile.JSBridge.newInstance($('body'));
                }
                return Mobile.JSBridge._instance;
            },
            defaults: {
                bindings: [
                    'equationPanelContentsUpdated',
                    'keyboardTextChangedInRange',
                    'toolbarButtonClicked'
                ]
            }
        },
        /** @prototype */
        {
            acknowledged: true,
            acknowledgeTimeoutId: null,
            queue: null,
            /**
             * Should not be called directly. See `getInstance()`.
             *
             * @constructor
             */
            init: function() {
                var self = this;

                this.queue = [];

                // Why do we want to eagerly bind to all known messages
                // from the iOS app? Because the panels and toolbars can be
                // active due to carry-over from a previous page, and so
                // it is possible they will cause more messages to be sent.
                // The messages are "sent" by trying to call functions in
                // the global namespace, and so if not present, this will
                // generate JS errors.
                $(self.options.bindings).each(function(i, name) {
                    self.bindJSBridgeMessage(name);
                });
            },
            /**
             * Converts a message passed from a wrapping UIWebView,
             * into a jQuery custom event.
             *
             * The event is of the form `jsbridge.NAME` and will have
             * a data object attached with an `args` key.
             *
             * WARNING: Ugly for the time being, but due to the app
             * implementation, we need to bind to a global function.
             * This means 'name' must be a safe name in the global
             * namespace!
             *
             * @param {String} name
             */
            bindJSBridgeMessage: function(name) {
                var self = this,
                    eventName = 'jsbridge.'+name;
                window[name] = function() {
                    var args = arguments;

                    // Execute the event asynchronously, so the app is
                    // not blocked.
                    setTimeout(function() {
                        self.element.trigger(eventName, {'args': args});
                    }, 1);
                };
            },
            sendString: function(action, message) {
                if (!this.queue.length && this.acknowledged) {
                    this.sendStringNow(action, message);
                }
                else {
                    this.appendQueueJob('sendStringNow', [action, message]);
                }
            },
            sendObject: function(action, message) {
                if (!this.queue.length && this.acknowledged) {
                    this.sendObjectNow(action, message);
                }
                else {
                    this.appendQueueJob('sendObjectNow', [action, message]);
                }
            },
            appendQueueJob: function(funcname, funcargs) {
                this.queue.push([funcname, funcargs]);
            },
            processQueueOnce: function() {
                if (!this.queue.length) {
                    return;
                }
                var item = this.queue.shift(),
                    funcname = item[0],
                    funcargs = item[1],
                    func = this[funcname];

                func.apply(this, funcargs);
            },
            acknowledge: function() {
                this.acknowledged = true;
                this.processQueueOnce();
            },
            startAcknowledgeTimeout: function() {
                var self = this;
                if (self.acknowledgeTimeoutId) {
                    clearTimeout(self.acknowledgeTimeoutId);
                    self.acknowledgeTimeoutId = null;
                }
                self.acknowledgeTimeoutId = setTimeout(function() {
                    self.acknowledgeTimeoutId = null;
                    self.acknowledge();
                }, 50);
            },
            /**
             * Sends an (action, message) pair to a wrapping UIWebView.
             *
             * @param action {String}
             * @param message {String}
             */
            sendStringNow: function(action, message) {
                Logger.info('JSBridge message sent: ' + action);
                Logger.info(message);
                this.acknowledged = false;
                if (App.Env.DEVICE_IS_IPAD_APP || App.Env.DEVICE_IS_ANDROID_APP) {
                    var obj = new Mobile.JSBridge.JSBridgeObj();
                    obj.addObject("action", action);
                    obj.addObject("message", message);
                    obj.sendBridgeObject();
                }
                else if (App.Env.DEVICE_IS_WIN8_APP) {
                    var data = {
                        action: action,
                        message: message
                    };
                    window.external.notify($.toJSON(data));
                }
                this.startAcknowledgeTimeout();
            },
            /**
             * Sends an (action, message) pair to a wrapping UIWebView.
             * The message object will be JSON encoded.
             *
             * @param action {String}
             * @param message {Object}
             */
            sendObjectNow: function(action, message) {
                Logger.info('JSBridge message sent: ' + action);
                Logger.info(message);
                this.acknowledged = false;
                if (App.Env.DEVICE_IS_IPAD_APP || App.Env.DEVICE_IS_ANDROID_APP) {
                    var obj = new Mobile.JSBridge.JSBridgeObj();
                    obj.addObject("action", action);
                    obj.addObject("format", "json");
                    message = this._addMessageRegistration(message);
                    var json = encodeURI($.toJSON(message));
                    obj.addObject("message", json);
                    obj.sendBridgeObject();
                }
                else if (App.Env.DEVICE_IS_WIN8_APP) {
                    var data = {
                        action: action,
                        message: message
                    };
                    window.external.notify($.toJSON(data));
                }
                this.startAcknowledgeTimeout();
            },
            _addMessageRegistration: function(message) {
                var bindings = {};
                $(this.options.bindings).each(function(i, name) {
                    bindings[name] = name;
                });
                var r = $.extend({}, message, bindings);
                return r;
            }

        });


    $(window.document).ready(function() {
        // This will instantiate the JSBridge on ready, regardless of
        // whether an input controller will use it.
        // This is important so that messages from the iOS app to hide/clear
        // the input panels can always be received
        Mobile.JSBridge.getInstance();
    });
});
