/*global
    define:false
 */
define([
    'jquery', 
    './resources/jquery.placeholder'
], function($) {
    'use strict';
    $(document).ready(function() {
        $('input[placeholder], textarea[placeholder]').placeholder();
    });
});
