/*globals
    App:false,
    Helpers:false,
    define:false
 */
define([
    'jquery', 
    'jquerypp/controller/controller'
], function($) {
    'use strict';

    var footerElm, bodyWrapperElm, contactDetailsContainer;

    /**
     * Affix footer to the bottom of the page if there is a gap underneath
     * the footer. This plugin assumes that the height of the page doesn't
     * change from its initial state.
     */
    var affix = function () {
        bodyWrapperElm.css('margin-bottom', 0);
        footerElm.removeClass('affix');

        var footerTop = footerElm.offset().top;
        var footerHeight = footerElm.outerHeight();
        var footerBottom = footerTop + footerHeight;

        var windowHeight = $(window).height();
        var bottomGap = windowHeight - footerBottom;

        if (bottomGap > 0) {
            bodyWrapperElm.css('margin-bottom', footerHeight);
            footerElm.addClass('affix');
        }
    };

    // Initialise when the DOM is ready.
    $(document).ready(function() {
        var bottomFooter = $('.js-footer-bottom');
        if (bottomFooter.length) {
            footerElm = bottomFooter;
            contactDetailsContainer = footerElm.find('.contact-details-container');
            bodyWrapperElm = $('.body-wrapper');

            // The footer starts out invisible to reduce FOUC.
            footerElm.css('visibility', 'visible');

            // Reposition footer on window resize.
            $(window).on('resize', affix);

            // Reposition footer if the page height changes. Since there
            // is no native event for this, we must poll for when it does.
            var lastDocumentHeight = null;
            var checkDocumentHeight = function() {
                var height = document.body.clientHeight;
                if (height !== lastDocumentHeight) {
                    affix();
                    lastDocumentHeight = height;
                }
                // Keeps checking every X milliseconds.
                setTimeout(checkDocumentHeight, 200);
            };
            // Positions the footer now, and start timeout chain to keep
            // checking for change in page height.
            checkDocumentHeight();
        }
    });
});
