/*globals
    gettext:false,
    ngettext:true,
    define:false,
    window:false
 */
define([], function() {
    'use strict';

    // Setup dummy pass-through functions in case the JS catalog fails
    // to be retrieved. e.g. when Django is not running.
    //
    // The expectation is that pages running through Django will manually
    // link the "/jsi18n/" URL for the JS translations, then load the
    // JavascriptMVC app. This means that window.gettext() will already be
    // defined, and this code won't affect the app.

    if (!window.gettext) {
        window.gettext = function(msgid) {
            return msgid;
        };

        window.ngettext = function(singular, plural, count) {
            return (count === 1) ? singular : plural;
        };

        window.gettext_noop = function(msgid) {
            return msgid;
        };

        window.pgettext = function(context, msgid) {
            return msgid;
        };

        window.npgettext = function(context, singular, plural, count) {
            return ngettext(singular, plural, count);
        };

        window.interpolate = function(fmt, obj, named) {
            if (named) {
                return fmt.replace(
                    /%\(\w+\)s/g,
                    function(match) {
                        return String(obj[match.slice(2,-2)]);
                    });
            }
            else {
                return fmt.replace(
                    /%s/g,
                    function(match) {
                        return String(obj.shift());
                    });
            }
        };
    }

    // Detect current language
    // Setup dummy translateable string to help determine language catalog
    window.get_language = function() {
        var lang = gettext('LANG');
        return (!lang || lang === 'LANG') ? 'en' : lang;
    };

    // Accent-folding
    // Characters used in French from: http://webdesign.about.com/od/localization/l/blhtmlcodes-fr.htm
    var accentMap = {

        "\xC0": "A", // Capital A-grave
        "\xE0": "a", // Lowercase a-grave
        "\xC2": "A", // Capital A-circumflex
        "\xE2": "a", // Lowercase a-circumflex
        "\xC6": "AE", // Capital AE Ligature
        "\xE6": "ae", // Lowercase AE Ligature
        "\xC7": "C", // Capital C-cedilla
        "\xE7": "c", // Lowercase c-cedilla
        "\xC8": "E", // Capital E-grave
        "\xE8": "e", // Lowercase e-grave
        "\xC9": "E", // Capital E-acute
        "\xE9": "e", // Lowercase e-acute
        "\xCA": "E", // Capital E-circumflex
        "\xEA": "e", // Lowercase e-circumflex
        "\xCB": "E", // Capital E-umlaut
        "\xEB": "e", // Lowercase e-umlaut
        "\xCE": "I", // Capital I-circumflex
        "\xEE": "i", // Lowercase i-circumflex
        "\xCF": "I", // Capital I-umlaut
        "\xEF": "i", // Lowercase i-umlaut
        "\xD4": "O", // Capital O-circumflex
        "\xF4": "o", // Lowercase o-circumflex
        "\xD9": "U", // Capital U-grave
        "\xF9": "u", // Lowercase u-grave
        "\xDB": "U", // Capital U-circumflex
        "\xFB": "u", // Lowercase U-circumflex
        "\xDC": "U", // Capital U-umlaut
        "\xFC": "u" // Lowercase U-umlaut
    };

    window.accentfold = function(term) {
        var result = "",
            letter = "";
        for (var i=0; i<term.length; i++) {
            letter = term.charAt(i);
            result += accentMap[letter] || letter;
        }
        return result;
    };
});