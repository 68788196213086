/*globals
    define:false
 */
define(
    ['jquery'],
    /**
     * @param {jQuery} $
     */
    function($) {
        'use strict';

        /**
         * @function jQuery.fn.dimensions
         * @param {Number|{width: Number, height: Number}} [widthOrSize]
         * @param {Number|undefined} [height]
         * @returns {jQuery|{width: Number, height: Number}}
         */
        $.fn.dimensions = function (widthOrSize, height) {
            if (arguments.length === 0) {
                return {
                    width: this.width(),
                    height: this.height()
                };
            }
            else {
                if (widthOrSize == null) {
                    throw new Error('widthOrSize == null');
                }
                else if (typeof (widthOrSize) == 'object') {
                    return this.width(widthOrSize.width).height(widthOrSize.height);
                }
                else if (height == null) {
                    throw new Error('height == null');
                }
                else {
                    return this.width(widthOrSize).height(height);
                }
            }
        };

        /**
         * @function jQuery.fn.dimensions
         * @param {Number|{width: Number, height: Number}} [widthOrSize]
         * @param {Number|undefined} [height]
         * @returns {jQuery|{width: Number, height: Number}}
         */
        $.fn.outerDimensions = function (widthOrSize, height) {
            if (arguments.length === 0) {
                return {
                    width: this.outerWidth(),
                    height: this.outerHeight()
                };
            }
            else {
                var setWidth, setHeight;
                if (widthOrSize == null) {
                    throw new Error('widthOrSize == null');
                }
                else if (typeof (widthOrSize) === 'object') {
                    setWidth = widthOrSize.width;
                    setHeight = widthOrSize.height;
                }
                else if (height == null) {
                    throw new Error('height == null');
                }
                else {
                    setWidth = widthOrSize;
                    setHeight = height;
                }

                var horizontalSpace = this.outerWidth() - this.width();
                var verticalSpace = this.outerHeight() - this.height();
                return this.width(setWidth - horizontalSpace).height(setHeight - verticalSpace);
            }
        };
    }
);
