/*globals
    define:false
 */
define(
    ['can/view/mustache'],
    function(can) {
        'use strict';

        /**
         * @name friendlyNumber
         * @example {{friendlyNumber 0.8}} -> 1
         * @example {{friendlyNumber null "None"}} -> None
		 *
         * Rounds the argument to nearest whole number, or displays the second
		 * argument if null.
         */
        can.Mustache.registerHelper('friendlyNumber', function(number, nullValue, options) {
			if (arguments.length === 2) {
				options = nullValue;
				nullValue = '-';
			}
            var numberResolved = can.Mustache.resolve(number);
            var nullValueResolved = can.Mustache.resolve(nullValue);
            if (numberResolved == null) {
                return nullValueResolved;
            }
            return Math.round(numberResolved).toString();
        });
    });
