/*globals
	define:false,
	YT:false
 */
define([
    'jquery',
    'can/util/jquery',
    'mustache!./youtube_video.mustache',
    'raven-js',
    'can/component',
    'can/map',
    'can/map/define'
], function($, can, youtubeVideoTemplate, Raven) {
    'use strict';


    /**
     * This Map instance is shared between all uc-youtube-video instances.
     */
    var youtubeApiState = new can.Map({
        /**
         * Whether the YouTube API has finished loading.
         */
        apiReady: false
    });

    /**
     * Handler that the YouTube API will call once ready.
     */
    var _oldOnYouTubeIframeAPIReady = window.onYouTubeIframeAPIReady;
    window.onYouTubeIframeAPIReady = function() {
        youtubeApiState.attr('apiReady', true);
        if (_oldOnYouTubeIframeAPIReady) {
            _oldOnYouTubeIframeAPIReady();
        }
    };


    /**
     * Component that plays videos from Youtube.
     *
     * @name uc-youtube-video
     * @example <uc-youtube-video video-id"{youtubeVideoId}" play="true"></uc-youtube-video>
     */
    can.Component.extend({
        tag: 'uc-youtube-video',
        template: youtubeVideoTemplate,
        scope: {
            define: {
                aspect169: {
                    type: 'boolean',
                    value: true
                },
                play: {
                    type: 'boolean',
                    value: false
                },
                videoId: {
                    type: 'string',
                    value: null
                },
                apiState: {
                    value: youtubeApiState
                }
            }
        },
        events: {
            _youtubeEmbed: null,
            inserted: function() {
                this.detectIsYoutubeAccessible(
                    function() {
                        this.loadYoutube();
                    }, function() {
                        this.showYoutubeBlockedMessage();
                    }
                );
            },
            removed: function() {
                this._youtubeEmbed = null;
                // To prevent JavaScript exceptions in the iframe when
                // its container is removed in IE.
                this.element.find('iframe').attr('src', '');
            },
            '{apiState} apiReady change': function(valType, e, attr, how, newVal) {
                if (newVal === true) {
                    this._initPlayer();
                }
            },
            /**
             * Detect whether youtube is blocked,
             * @param success Success callback
             * @param failure Failure callback
             */
            detectIsYoutubeAccessible: function(success, failure) {
                var self = this;
                var url = '//youtube.com/favicon.ico?_=' + new Date().getTime().toString();
                var image = new Image();

                image.onload = function(){
                    if (image.height > 0) {
                        success.call(self);
                    } else {
                        failure.call(self);
                    }
                };

                image.onerror = function(){
                    failure.call(self);
                };

                image.src = url;
            },
            loadYoutube: function() {
                this._asyncLoadPlayerAPI();

                // If the player api has already loaded, kick off the
                // player straight away. This is for the subsequent players
                // installed on the page.
                if (this.viewModel.apiState.attr('apiReady')) {
                    this._initPlayer();
                }
            },
            showYoutubeBlockedMessage: function() {
                var heading = "It looks like we had problems connecting to YouTube. ";
                var message = "Please contact your network administrator.";
                var embedElement = this.element.find('.youtube_embed');
                embedElement.before($('<div class="alert alert-error"><h4>' + heading + '</h4><p>' + message + '</p></div>'));
                Raven.captureMessage(heading + message);
            },
            /**
             * Check whether youtube player api has ready
             * @returns {boolean}
             */
            isPlayerApiLoaded: function(){
                return this.viewModel.apiState.attr('apiReady') === true;
            },
            /**
             * Load youtube player API
             * @private
             */
            _asyncLoadPlayerAPI: function() {

                if (!this.isPlayerApiLoaded()) {
                    var tag = document.createElement('script');
                    tag.src = "https://www.youtube.com/iframe_api";
                    var firstScriptTag = document.getElementsByTagName('script')[0];
                    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
                }
            },
            /**
             * Initialise the YouTube player.
             * @private
             */
            _initPlayer: function() {
                var self = this;
                var elementId = this.element.find('.youtube_embed').attr('id');
                this._youtubeEmbed = new YT.Player(elementId, {
                    videoId: this.viewModel.attr('videoId'),
                    playerVars: {
                        autoplay: (this.viewModel.attr('play') === true) ? 1 : 0,
                        enablejsapi: 1, // Required to use jsapi
                        rel: 0,         // Do not show related videos after playback is finished
                        modestbranding: 1,      // Do not show youtube branding api
                        showinfo: 0,            //  Hide title, uploaders
                        cc_load_policy: 0,      //  Hide subtitles
                        theme: 'light',         // Light theme fits better visually.
                        origin: self._getDocumentHost()      // This option doesn't work
                    },
                    events: {
                        'onStateChange': function() {
                            // We can only set quality to 720p after the
                            // player is ready. We do this because the
                            // default quality is not high enough to read
                            // text in the videos.
                            if (self._youtubeEmbed) {
                                if (self._youtubeEmbed.getPlayerState() === YT.PlayerState.PLAYING) {
                                    self._youtubeEmbed.setPlaybackQuality('hd720');
                                }
                            }
                        }
                    }
                });
            },
            /**
             * Return the page protocol + hostname + port (if other than 80)
             * @returns {string}
             * @private
             */
            _getDocumentHost: function() {
                var loc = window.location;
                return loc.protocol + '//' + loc.hostname + (loc.port ? ':' + loc.port: '');
            }
        }
    });
});
