/*globals
    define:false
 */
define([
    './add_offset',
    './bandify',
    './compare',
    './condition',
    './cycle',
    './hack_invert_colour',
    './spa_url',
    './mpa_url',
    './number_to_alphabet',
    './friendly_number',
    './if_any',
    './show_datetime',
    './math_round',
    './if_hybrid_app',
    './set_variable',
    './static',
    './trans',
    './url',
    './timedelta_format'
], function() {
});
