/** This file must be compatible with IE8 so the browser update warning is displayed. */

/*globals
    define:false
*/

define([
    'jquery',
    'lodash',
    'helpers/capture_interactions/interaction',
    'helpers/capture_interactions/hash_interaction',
    'helpers/capture_interactions/keyboard_interaction',
    'helpers/capture_interactions/mouse_interaction',
    'helpers/capture_interactions/paste_interaction',
    'helpers/string_util/string_util',
    'raven-js',
    'jquerypp/class/class',
    'jquerypp/controller/controller'
], /**
 * @param {jQuery} $
 * @param {lodash} _
 * @param {Helpers.CaptureInteractions.Interaction} Interaction
 * @param {Helpers.CaptureInteractions.HashInteraction} HashInteraction
 * @param {Helpers.CaptureInteractions.KeyboardInteraction} KeyboardInteraction
 * @param {Helpers.CaptureInteractions.MouseOrTouchInteraction} MouseOrTouchInteraction
 * @param {Helpers.CaptureInteractions.PasteInteraction} PasteInteraction
 * @param {Helpers.StringUtil} StringUtil
 */
function ($, _, Interaction, HashInteraction, KeyboardInteraction, MouseOrTouchInteraction, PasteInteraction, StringUtil, Raven) {
    'use strict';

    var SIZE_LIMIT = 12288;
    var IGNORED_CONTAINERS = [
        'w-feedback'
    ].join(', ');

    /**
     * @class Helpers.CaptureInteractions.Capture
     * @extends jQuery.Controller
     */
    var Capture = $.Controller.extend(
        'Helpers.CaptureInteractions.Capture',
        /** @static */
        {
            /** {Helpers.CaptureInteractions.Capture} singleInstance */
            singleInstance: null
        },
        /** @prototype */
        {
            /** @type {Helpers.CaptureInteractions.Interaction[]} interactions */
            interactions: undefined,
            /** @type {Object[]} events */
            events: undefined,

            '{window} hashchange': function () {
                this.logInteractionIfApplicable(new HashInteraction(window.location.hash));
            },

            init: function () {
                var self = this;

                this.interactions = [];
                this.events = [];

                // Use addEventListener() with 'useCapture' set to true
                // to capture events on the way down so event.stopPropagation()
                // and 'return false' in event callbacks do not prevent logging.

                // Get keyboard interactions
                this.addEvent(this.element[0], 'keydown', function (event) {
                    var lastInteraction = _.last(self.interactions);
                    if (lastInteraction && lastInteraction instanceof KeyboardInteraction && event.target === lastInteraction.element) {
                        lastInteraction.append(event);
                    } else {
                        self.logInteractionIfApplicable(new KeyboardInteraction(event));
                    }
                });

                // Get mouse and touch interactions
                var addMouseOrTouchEventHandler = function(prefix, suffix) {
                    self.addEvent(self.element[0], prefix + suffix, function(event) {
                        self.logInteractionIfApplicable(new MouseOrTouchInteraction(prefix + suffix, event));
                    });
                };
                $.each(['mouse', 'pointer'], function(index, prefix) {
                    $.each(['down', 'up'], function(index2, suffix) {
                        addMouseOrTouchEventHandler(prefix, suffix);
                    });
                });
                $.each(['touch'], function(index, prefix) {
                    $.each(['start', 'end'], function(index2, suffix) {
                        addMouseOrTouchEventHandler(prefix, suffix);
                    });
                });

                this.addEvent(this.element[0], 'paste', function (event) {
                    self.logInteractionIfApplicable(new PasteInteraction(event));
                });
            },

            /**
             * @param {Helpers.CaptureInteractions.Interaction} interaction
             */
            logInteractionIfApplicable: function (interaction) {
                if (this.isOutsideIgnoredContainers(interaction)) {
                    this.interactions.push(interaction);
                    if (this.interactions.length > SIZE_LIMIT / 150) {
                        this.interactions.shift();
                    }
                }
            },

            /**
             * @param {Helpers.CaptureInteractions.Interaction} interaction
             */
            isOutsideIgnoredContainers: function (interaction) {
                return !$(interaction.element).parents().addBack().is(IGNORED_CONTAINERS);
            },

            destroy: function () {
                this.removeEvents();
                this._super();
            },

            /**
             * Event listeners added using this method will be removed on destroy().
             * @param {HTMLElement} container
             * @param {string} eventName
             * @param {Function} handler
             **/
            addEvent: function (container, eventName, handler) {
                if (container.addEventListener) {
                    container.addEventListener(eventName, handler, true);
                }
                else {
                    container.attachEvent(eventName, handler, true);
                }
                this.events.push([container, eventName, handler]);
            },

            /** Remove all event listeners added via addEvent() */
            removeEvents: function () {
                $.each(this.events, function (index, eventParams) {
                    var container = eventParams[0];
                    var eventName = eventParams[1];
                    var handler = eventParams[2];
                    container.removeEventListener(eventName, handler, true);
                });
            },

            exceptionMetadataForRaven: function () {
                var browserDiagnostics = {
                    location: window.location.href,
                    hash: window.location.hash,
                    cookie: document.cookie,
                    userAgent: navigator.userAgent,
                    platform: navigator.platform,
                    language: (navigator.language || navigator.systemLanguage),
                    size: {
                        screen: StringUtil.format('{0} x {1}', screen.width, screen.height),
                        // Modern browsers and IE have different ways of reporting window size.
                        // Let jQuery figure it out.
                        window: StringUtil.format('{0} x {1}', $(window).width(), $(window).height())
                    }
                };

                var baseUrl = StringUtil.format('{0}//{1}', location.protocol, location.host);
                var subtopicIdSeg = (/\bsubtopicId\=(\d+)\b/).exec(browserDiagnostics.hash);
                var problemTemplateSeg = (/\bpt\/(\d+)\b/).exec(browserDiagnostics.hash);

                if (subtopicIdSeg !== null) {
                    browserDiagnostics.subtopicPage = StringUtil.format(
                        '{0}/admin/curriculum/subtopic/?id={1}',
                        baseUrl, subtopicIdSeg[1]);
                }
                if (problemTemplateSeg !== null) {
                    browserDiagnostics.problemTemplatePage = StringUtil.format(
                        '{0}/admin/problem_templates/problemtemplate/{1}',
                        baseUrl, problemTemplateSeg[1]);
                }

                var interactions = this.interactions.map(function(interaction) {
                    return interaction.formatForRaven();
                }).reverse();
                var extra = {
                    browserDiagnostics: browserDiagnostics,
                    interactions: interactions
                };

                while (encodeURIComponent(JSON.stringify(extra)).length > SIZE_LIMIT) {
                    extra.interactions.pop();
                }

                return { extra: extra };
            }
        }
    );

    $(document).ready(function () {
        Capture.singleInstance = Capture.singleInstance || new Capture($('body')[0]);
        Raven.setDataCallback(function(data) {
            $.extend(
                true,
                data,
                Capture.singleInstance.exceptionMetadataForRaven() || {}
            );
            return data;
        });
    });

    return Capture;
});
