/* eslint-disable no-console */
import { Severity } from '@sentry/browser';

/**
 * InvariantViolation is a custom error type.
 *
 * InvariantViolation is useful for indicating that a condition should never
 * occur at runtime. Static typing guarantees should always be preferred to
 * Invariant Violations. In cases where static type guarantees are not strong
 * enough to provide a guarantee, use InvariantViolation instead.
 */
export class InvariantViolation extends Error {
  constructor(...args: Array<any>) {
    super(...args);
    this.name = 'InvariantViolation';
  }
}

/**
 * DeprecationWarning is a custom error type.
 *
 * DeprecationWarning should be used to indicate that a codepath is deprecated.
 * A good message will suggest an alternative, preferred approach to
 * developers.
 */
export class DeprecationWarning extends Error {
  constructor(...args: Array<any>) {
    super(...args);
    this.name = 'DeprecationWarning';
  }
}

/**
 * NotFoundError is a custom error type.
 *
 * NotFoundError should be used to indicate that data requested by the user
 * (e.g. via url params) is not found in the database.
 */
export class NotFoundError extends Error {
  constructor(...args: Array<any>) {
    super(...args);
    this.name = 'NotFoundError';
  }
}

type SentryOptions = {
  tags?: { [key: string]: string };
  extra?: { [key: string]: any };
};

/**
 * Log an exception or message.
 * If we are in development, logs the exception to the browser console.
 * In production, logs the exception to Sentry, instead.
 *
 * @param {*} message The exception to log, logged in both console and sentry
 * @param {*} level The error level at which to log this message;
 * can be 'INFO', 'WARNING' or 'ERROR'
 * @param {*} options Extra Sentry context information; ignored in development mode.
 * @returns {*} Nothing
 */
function log(
  message: Error | string,
  level: 'INFO' | 'WARNING' | 'ERROR',
  options: SentryOptions = {},
) {
  if (process.env.NODE_ENV === 'production') {
    if (window.Sentry) {
      window.Sentry.withScope(scope => {
        // Set up the options

        if (options.tags) {
          Object.keys(options.tags).forEach(
            tag => options.tags && scope.setTag(tag, options.tags[tag]),
          );
        }
        if (options.extra) {
          Object.keys(options.extra).forEach(
            extra =>
              options.extra && scope.setExtra(extra, options.extra[extra]),
          );
        }

        if (level === 'ERROR') {
          // only log errors with Sentry
          if (message instanceof Error) {
            scope.setFingerprint([message.name, message.message]);
            window.Sentry.captureException(message);
          } else {
            window.Sentry.captureMessage(message, Severity.Error);
          }
        }
      });
    }
    // TODO: Capture info & warning level application logs via another medium?
  } else if (process.env.NODE_ENV === 'test') {
    // Test environment
    // do nothing
  } else {
    // Dev environment
    switch (level) {
      case 'INFO':
        console.log(message);
        break;
      case 'WARNING':
        console.warn(message);
        break;
      case 'ERROR':
        console.error(message);
        break;
      default:
      // This case should be unreachable
    }
  }
}
/**
 * Log an info message. In development environments, logs a message. Does
 * nothing in production / test environments.
 *
 * @param {*} message The exception to log, logged in the console
 * @returns {*} Nothing
 */
function info(message: Error | string) {
  log(message, 'INFO');
}

/**
 * Log a warning. In development environments, logs a warning. Does
 * nothing in production / test environments.
 *
 * @param {*} message The exception to log, logged in the console
 * @returns {*} Nothing
 */
function warn(message: Error | string) {
  log(message, 'WARNING');
}

/**
 * Log an error message.
 * If we are in development, logs the exception to the browser console.
 * In production, logs the exception to Sentry, instead.
 *
 * @param {*} message The exception to log, logged in both console and sentry
 * @param {*} options Extra Sentry context information; ignored in development mode.
 * @returns {*} Nothing
 */
function error(message: Error | string, options?: SentryOptions) {
  log(message, 'ERROR', options);
}

export const Logger = Object.freeze({
  info,
  warn,
  error,
});
