/*globals
 define:false
 */
define([
    'jquery',
    // Twitter Bootstrap 3
    './resources/alert', 
    './resources/button', 
    './resources/carousel', 
    './resources/dropdown', 
    './resources/modal', 
    './resources/tooltip', 
    './resources/popover', 
    './resources/tab', 
    './resources/affix', 
    './resources/collapse', 
    './resources/scrollspy', 
    './resources/transition',
        // Flat-UI extensions to Twitter Bootstrap:
    './resources/bootstrap-select', 
    //'./resources/bootstrap-switch',
    './resources/flatui-checkbox'
    // Disabled flatui-radio.js because it does not align with text well,
    // and does not support unstyled radio buttons.
    // './resources/flatui-radio'
],
function($) {
    'use strict';

    $(document.body).off('.data-api');
});
