/*globals
    define:false
 */
define(
    ['jquery'],

    function($) {
        'use strict';

        /**
         * In at least IE8 and IE9, we've noticed that AJAX requests are being
         * automatically cached. This affects API calls made from jQuery.Model
         * where we GET the same object, to look for updates.
         *
         * This global setting turns off caching for all AJAX requests.
         */
        $.ajaxSetup({
            cache: false
        });
    });
