/* globals can, define, Helpers */

define(['can/view/mustache', 'helpers/url/url'], function () {
    'use strict';

    can.Mustache.registerHelper('url', function() {
        for (var i = 0; i < arguments.length; i++) {
            arguments[i] = can.Mustache.resolve(arguments[i]);
        }
        return Helpers.Url.get.apply(Helpers.Url, arguments);
    });
});
