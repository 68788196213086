/*globals
    App:false,
    define:false
 */
define(
    ['can/view/mustache'],
    function(can) {
        'use strict';

        /**
         * @name numberToAlphabet
         * @example {{numberToAlphabet 0}} -> "a"
         */
        can.Mustache.registerHelper('numberToAlphabet', function(number) {
            return String.fromCharCode(97 + can.Mustache.resolve(number));
        });
    });
