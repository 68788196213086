/*global
    define:false
 */
define([
    'jquery',
    'mustache!learn/signup_video/signup_video.mustache',
    'app/core/core',
    'bootstrap3/bootstrap3',
    'helpers/browser_warning/browser_warning',
    'ui_components/modal_video/modal_video',
    'helpers/placeholder_shim/placeholder_shim'
], function($, learnAppVideoTemplate) {
    $(document).ready(function () {
        $('.learn-video-launcher').click(function() {
            UIComponents.ModalVideo.renderModal(learnAppVideoTemplate);
        });
    });
});
