/*globals
    Helpers:false,
    define:false
 */
define([
    'can/view/mustache', 
    'helpers/genimage_util/genimage_util'
], function(can) {
    "use strict";

    /**
     * @name hackInvertColour
     * @example {{{hackInvertColour someHtml}}}
     *
     * Applies ``hackInvertColour()`` to applicable images found in input.
     */
    can.Mustache.registerHelper('hackInvertColour', function(stringIn) {
        return Helpers.GenImageUtil.hackInvertColour(
            can.Mustache.resolve(stringIn));
    });
});
