/*globals
    define:false
*/

define([
    'jquery',
    'lodash',
    'helpers/string_util/string_util',
    'jquerypp/class/class'
], /**
 * @param {jQuery} $
 * @param {lodash} _
 * @param {Helpers.StringUtil} StringUtil
 */
function ($, _, StringUtil) {
    'use strict';

    window.ABSTRACT_METHOD_EXCEPTION = 'An abstract method cannot be directly called.';

    /**
     * @class Helpers.CaptureInteractions.Interaction
     * @extends jQuery.Class
     */
    return $.Class.extend(
        'Helpers.CaptureInteractions.Interaction',
        /** @prototype */
        {
            /** {Date} timestamp */
            timestamp: null,
            /** @type {String} type */
            type: null,
            /** @type {HTMLElement} element */
            element: null,

            init: function (type, element) {
                this.timestamp = new Date();
                this.type = type;
                this.element = element;
            },

            getElementCss: function () {
                if (!this.element) {
                    return undefined;
                }

                var isComplete = function (id, $element, elements) {
                    if (!!id || !$element.length || _.includes(['html', 'body'], $element[0].tagName.toLowerCase())) {
                        return true;
                    }

                    elements.reverse();
                    var returnValue = $(elements.join(' ')).length === 1;
                    elements.reverse();
                    return returnValue;
                };

                var elements = [];
                var $element = $(this.element);
                var id;
                do {
                    id = $element.attr('id');
                    var elementDescription = $element[0].tagName;
                    if (id) {
                        elementDescription += '#' + id;
                    }
                    var klass = ($element.attr('class') || '').trim();
                    if (klass) {
                        elementDescription += '.' + klass.replace(/\s+/g, '.');
                    }
                    elements.push(elementDescription);
                    $element = $element.parent();
                } while (!isComplete(id, $element, elements));
                elements.reverse();
                return elements.join(' ');
            },

            /**
             * Gets an XPath for an element which describes its hierarchical location.
             * You can use document.evaluate to work out where the element is in the
             * document.
             *
             *   e.g.
             *   document.evaluate("/html/body//h2", document, null, XPathResult.ANY_TYPE, null);
             *
             * See https://developer.mozilla.org/en-US/docs/Web/API/document.evaluate for
             * more inforation.
             */
            getElementXPath: function() {
                if (!this.element) {
                    return undefined;
                }

                if (this.element.id) {
                    return '//*[@id="' + this.element.id + '"]';
                }
                else {
                    var paths = [];

                    // Use nodeName (instead of localName) so namespace prefix is included (if any).
                    for (var element = this.element; element && element.nodeType === 1; element = element.parentNode)  {
                        var index = 0;
                        // EXTRA TEST FOR ELEMENT.ID
                        if (element && element.id) {
                            paths.splice(0, 0, '/*[@id="' + element.id + '"]');
                            break;
                        }

                        for (var sibling = element.previousSibling; sibling; sibling = sibling.previousSibling) {
                            // Ignore document type declaration.
                            if (sibling.nodeType === Node.DOCUMENT_TYPE_NODE) {
                                continue;
                            }

                            if (sibling.nodeName === element.nodeName) {
                                index+=1;
                            }
                        }

                        var tagName = element.nodeName.toLowerCase();
                        var pathIndex = (index ? "[" + (index+1) + "]" : "");
                        paths.splice(0, 0, tagName + pathIndex);
                    }

                    return paths.length ? "/" + paths.join("/") : null;
                }
            },

            formatForRaven: function () {
                var obj = {
                    timestamp: this.timestamp.toString(),
                    type: this.type
                };
                if (this.element) {
                    var elementCss = this.getElementCss();
                    var elementXPath = this.getElementXPath();
                    if (elementCss.length < 50 || elementCss.length < elementXPath.length) {
                        obj.css = elementCss;
                    }
                    else {
                        obj.xPath = StringUtil.format('{0} ({1})', elementXPath, _.last(elementCss.split(' ')));
                    }
                    if (!$(this.element).parents().addBack().is('body')) {
                        obj.notInDom = true;
                    }
                }
                return obj;
            }
        }
    );
});
