define([
    'jquery', 
    'helpers/capture_interactions/interaction',
    'jquerypp/class/class'
], /**
 * @param {jQuery} $
 * @param {Helpers.CaptureInteractions.Interaction} Interaction
 */
function ($, Interaction) {
    'use strict';

    /**
     * @class Helpers.CaptureInteractions.HashInteraction
     * @extends Helpers.CaptureInteractions.Interaction
     */
    return Interaction.extend(
        'Helpers.CaptureInteractions.HashInteraction',
        /** @prototype */
        {
            /** @type {String} hash */
            hash: undefined,

            init: function (hash) {
                this.hash = hash;
                this._super('hash', null);
            },

            formatForRaven: function () {
                return $.extend(this._super(), { hash: this.hash });
            }
        }
    );
});
