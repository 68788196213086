/*globals
    define:false
*/

define([
],
function () {
    'use strict';
    var userAgent = window.navigator.userAgent;
    var isAndroidApp = (/MathspaceAND/i).test(userAgent);
    var isIpadApp = (/MathspaceIOS/).test(userAgent);
    var isWin8TabletApp = (/WebView|MSAppHost/).test(userAgent);
    return {
        isHybridApp: function(){
            return isAndroidApp || isIpadApp || isWin8TabletApp;
        }
    };
});
