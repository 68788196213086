/*global
    define:false,
    Helpers:false,
    document:false
 */
define([
    'jquery', 
    'jquerypp/dom/cookie/cookie', 
    'jquerypp/controller/controller'
], function($){
    "use strict";

    // Configured in Django settings
    var CSRF_TOKEN_NAME = 'csrftoken';

    /**
     *  ## CSRF helper
     *
     *  This plugin will read Django's CSRF token from the document cookie, and
     *  then configure all jQuery AJAX requests to include a X-CSRFToken header.
     *
     *  This means that AJAX requests may pass though Django's
     *  [CSRF security mechanism](https://docs.djangoproject.com/en/dev/ref/contrib/csrf/).
     *
     *  It is intended for pages which have AJAX requests.
     *  Steal this module and it takes effect automatically.
     *
     *  Example usage:
     *
     *  @codestart
     *  steal(
     *      'jquery',
     *      'helpers/ajax_csrf',
     *      function($) {
     *
     *          // **No need** to have 'csrfmiddlewaretoken' in the data !
     *          var data = {
     *              'foo':'bar'
     *          }
     *          $.post('/api/foo/', data, function() {});
     *      }
     *  );
     *  @codeend
     *
     *  ## How django checks CSRF_TOKEN:
     *  Step1: Django firstly ensures that 'csrf_token' is in the cookie of
     *  every HttpRequest (CsrfViewMiddleware -> process_response).
     *
     *  Step2: Before Django processes a view, it checks whether 'csrf_token' is
     *  in the cookie, and generates a new one if it doesn't exist.
     *  It rejects the request if the HTTP verb needs CSRF (POST/UPDATE/ETC..).
     *
     *  Step3: If the request is a POST request, it looks for
     *  'csrfmiddlewaretoken' in POST variables, this is generally generated
     *  by the `{% csrf_token %}` templatetag
     *  If that's empty, then look up the token in request header
     *  'HTTP_X_CSRFTOKEN'.
     *
     *  Finally: Compare the csrf_token sent from client with the token django
     *  got from the request, reject if they don't match.
     *
     */
    $.Controller.extend('Helpers.AjaxCsrf',
    {
        /**
         * Check whether the http method needs CSRF protection.
         * GET|HEAD|OPTIONS|TRACE is safe methods
         * @param methodName Http Verb
         * @return {Boolean}
         */
        _isMethodCsrfSafe: function(methodName) {
            return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(methodName));
        },
        /**
         * test that a given url is a same-origin URL
         * url could be relative or scheme relative or absolute
         * @param url
         * @return {Boolean}
         */
        _isFromSameOrigin: function (url) {

            var host = document.location.host, // host + port
                protocol = document.location.protocol,
                sr_origin = '//' + host,
                origin = protocol + sr_origin;

            // Allow absolute or scheme relative URLs to same origin
            return (url == origin || url.slice(0, origin.length + 1) == origin + '/') ||
                (url == sr_origin || url.slice(0, sr_origin.length + 1) == sr_origin + '/') ||
                // or any other URL that isn't scheme relative or absolute i.e relative.
                !(/^(\/\/|http:|https:).*/.test(url));
        },
        getCsrf: function() {
            return $.cookie(CSRF_TOKEN_NAME);
        },
        setupHttpCsrfToken: function(){
            var self = this;

            $.ajaxSetup({
                beforeSend: function(xhr, settings) {
                    if (!self._isMethodCsrfSafe(settings.type) && self._isFromSameOrigin(settings.url)) {
                        // Send the token to same-origin, relative URLs only.
                        // Send the token only if the method warrants CSRF protection
                        // Using the CSRFToken value acquired earlier
                        xhr.setRequestHeader("X-CSRFToken", self.getCsrf());
                    }

                    // To assist with the debugging of faulty AJAX requests,
                    // provide a header similar to ``HTTP-REFERER`` but also
                    // includes the URL hash.
                    xhr.setRequestHeader("X-Referer-Spa", document.location.toString());
                }
            });
        }
    },
    /* Prototype */
    {

    });

    // Setup the ajax as soon as the module has downloaded
    Helpers.AjaxCsrf.setupHttpCsrfToken();

});