/*globals
    Helpers:false,
    define:false
 */
define([
    'jquery', 
    'jquerypp/controller/controller'
], function($) {
    'use strict';
    
    /**
     * Detects if the user has unintentionally double-clicked a link or
     * button element, and prevents the second click from taking effect.
     *
     * Useful on forms such as payment forms to avoid unintended double
     * payment -- anywhere a click would trigger a state change.
     *
     * @class Helpers.DblClickShield
     * @extends jQuery.Controller
     */
    $.Controller.extend('Helpers.DblClickShield',
        /** @static */
        {
            /**
             * Instantiates the controller and binds to the right place in
             * the document body.
             * @returns {Helpers.DblClickShield}
             */
            addNewInstanceToBody: function() {
                var elm = $('body');
                return Helpers.DblClickShield.newInstance(elm);
            }
        },
        /** @prototype */
        {
            'button, a, input[type="submit"] click': function(elm, event) {
                if (elm.hasClass('disabled')) {
                    // Link elements can't be made inactive with a
                    // `disabled` attribute, we must prevent the browser
                    // following the link manually.
                    event.preventDefault();
                }
                else {
                    // Try to nullify the second click by having it fall on
                    // a disabled element: disable it after this event
                    // process has completed (so that we don't prevent this
                    // first click in IE), then re-enable a short moment
                    // after.
                    setTimeout(function() {
                        elm.prop('disabled', true);
                        elm.addClass('disabled');
                    }, 0);
                    setTimeout(function() {
                        elm.prop('disabled', false);
                        elm.removeClass('disabled');
                    }, 1000);
                }
            }
        });


    // Auto-load this helper when the document is ready.
    $(document).ready(function() {
        Helpers.DblClickShield.addNewInstanceToBody();
    });

});
