/*globals
    define:false,
    document:false
 */
define(
    ['jquery', 'raven-js'],
    function($, Raven) {
        'use strict';

        // Maximum number of attempts on a request, before giving up.
        var DEFAULT_MAX_TRIES = 3;
        // Maximum delay in milliseconds before each retry. It will be doubled
        // for each successive retry, in an exponential back-off fashion.
        var DEFAULT_RETRY_DELAY = 4000;
        // Status codes that will trigger the retry mechanism.
        var RESPONSE_STATUS_TO_RETRY = {
            'undefined': true,
            // Failed connections have status of 0 under WebKit.
            '0': true,
            // 502 Bad Gateway errors.
            '502': true,
            // 503 Service Unavailable errors.
            '503': true,
            // Under Internet Explorer, the set of 12xxx errors are to do
            // with network connection issues. This list created from messages
            // in Sentry.
            // See: http://support.microsoft.com/kb/193625
            '12002': true,
            '12019': true,
            '12030': true,
            '12029': true,
            '12031': true,
            '12152': true
        };

        // Setup an AJAX prefilter to implement the retry behavior.
        // Preserves expected behavior around success/error/complete callbacks,
        // AND Deferred style callbacks.
        // See: http://stackoverflow.com/a/12446363
        $.ajaxPrefilter(function(options, originalOptions, jqXHR) {

            // Apply defaults.
            options.maxTries = originalOptions.maxTries || DEFAULT_MAX_TRIES;
            options.retryDelay = originalOptions.retryDelay || DEFAULT_RETRY_DELAY;

            // Retry behavior can be disabled by setting maxTries to 1.
            // If that's the case, we fallback to default behavior.
            if (options.maxTries === 1) {
                return;
            }

            // If this is the first try...
            if (options._tries == null) {
                options._tries = 1;
            }
            else {
                // Remember to count the number of tries.
                options._tries += 1;
            }

            options._tryStartTime = (new Date()).getTime();

            // Remember the original callbacks, after any number of retries.
            // This helps to ensure the solution is transparent; callers of
            // `$.ajax()` don't have to be aware of the retry behavior.
            var successCallback = options.success || function() {};
            var errorCallback = options.error || function() {};
            var completeCallback = options.complete || function() {};
            options.success = null;
            options.error = null;
            options.complete = null;

            // Our own Deferred object, to handle done/fail callbacks.
            var dfd = $.Deferred();

            // If the request works, return normally.
            jqXHR.done(dfd.resolve);
            jqXHR.done(function() {
                var args = Array.prototype.slice.apply(arguments);
                successCallback.apply(options.context, args);
                completeCallback.apply(options.context, args);
                hideRetryMessage();
            });

            // If the request fails, we may retry the request.
            jqXHR.fail(function() {
                var args = Array.prototype.slice.apply(arguments);
                var shouldRetry = shouldRetryResponse(jqXHR);
                var isLastTry = (options._tries >= options.maxTries);

                // If this request can't be retried one more time...
                if (isLastTry || !shouldRetry) {
                    // Proceed to fail the request.
                    dfd.rejectWith(jqXHR, args);
                    errorCallback.apply(this, args);
                    completeCallback.apply(this, args);
                    hideRetryMessage();
                    showFailedMessage();
                }
                else {
                    // Else this request can be retried.
                    showRetryMessage();
                    // Retry again, after a delay for exponential back-off.
                    var maxDelay = options.retryDelay * Math.pow(2, options._tries - 1);
                    var delay = Math.floor(Math.random() * maxDelay);
                    setTimeout(function() {
                        var newOptions = $.extend({}, options, {
                            success: successCallback,
                            error: errorCallback,
                            complete: completeCallback
                        });
                        $.ajax(newOptions).then(dfd.resolve, dfd.reject);
                    }, delay);
                }

                if (shouldLogResponse(jqXHR)) {
                    logResponse(options, jqXHR);
                }
            });

            // This replaces the regular Deferred that is returned from
            // `$.ajax()` with our one which will only resolve after our retry
            // behavior. This helps to ensure the solution is transparent;
            // callers of `$.ajax()` don't have to be aware of the retry
            // behavior.
            return dfd.promise(jqXHR);
        });

        // For logging 504 and 522 responses, where the problem lies somewhere
        // between the user, Cloudflare, Rackspace LB, and our app servers.
        // See Trello #8405.
        function looksLikeBuggyTimeoutResponses(jqXHR) {
            return (jqXHR.status === 504) || (jqXHR.status === 522);
        }

        function shouldRetryResponse(jqXHR) {
            return RESPONSE_STATUS_TO_RETRY[jqXHR.status];
        }

        function shouldLogResponse(jqXHR) {
            return looksLikeBuggyTimeoutResponses(jqXHR);
        }

        function logResponse(ajaxOptions, jqXHR) {
            var requestUrl = ajaxOptions.type + ' ' + ajaxOptions.url;
            var statusMessage = jqXHR.status + ' ' + jqXHR.statusText;
            var endTime = (new Date()).getTime();
            var responseTime = (endTime - ajaxOptions._tryStartTime);
            var messageTitle = 'Unexpected response: ' + statusMessage;
            var documentLocation = document.location.toString();
            // Get some trace data from CloudFlare:
            var traceRequest = $.ajax({
                url: '/cdn-cgi/trace',
                maxTries: 1
            });
            traceRequest.done(function(traceResult) {
                sendLog(traceResult);
            });
            traceRequest.fail(function(traceXHR) {
                sendLog('(failed: ' + traceXHR.status + ' ' + traceXHR.statusText + ')');
            });
            var sendLog = function(traceResult) {
                var messageLines = [
                    messageTitle,
                    'Request:',
                    'URL: ' + requestUrl,
                    'X-Referer-Spa: ' + documentLocation,
                    'ajax_retry:tries: ' + ajaxOptions._tries,
                    'ajax_retry:responseTime: ' + responseTime,
                    '',
                    'Request Data:',
                    jqXHR.data,
                    '',
                    'Response:',
                    'Status: ' + statusMessage,
                    'Server: ' + jqXHR.getResponseHeader('Server'),
                    'Date: ' + jqXHR.getResponseHeader('Date'),
                    'Cf-Ray: ' + jqXHR.getResponseHeader('Cf-Ray'),
                    'X-Node: ' + jqXHR.getResponseHeader('X-Node'),
                    'Site-Version: ' + jqXHR.getResponseHeader('Site-Version'),
                    '',
                    'Result from /cdn-cgi/trace:',
                    traceResult
                ];
                var message = messageLines.join('\n');
                // To have messages group up better, we'll group based on the
                // response status and request URL:
                var requestUrlWithoutIds = requestUrl.replace(
                    /(\/\d+\/)/g,
                    '/*/'
                );
                var fingerprint = [messageTitle, requestUrlWithoutIds];
                Raven.captureException(message, {
                    fingerprint: fingerprint
                });
            };
        }

        function showRetryMessage() {
            // Message for when the request fails, but is being retried.
            $(document).trigger('showretry.ajaxretry');
        }

        function hideRetryMessage() {
            // Hides the retry message.
            $(document).trigger('hideretry.ajaxretry');
        }

        function showFailedMessage() {
            // Message for when the request fails, for any reason at all.
            $(document).trigger('showfail.ajaxretry');
        }

    });
