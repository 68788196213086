import { isEmpty, pickBy } from 'ramda';

import { unwrap, unsafeKeys } from 'ms-utils/typescript-utils';

export type QueryStringBoolean = 'true' | 'false';
export type QueryStringPositiveFlag = 'Y' | '';
export type QueryStringInt = string;
export type QueryStringObject = Record<string, string | null | undefined>;

export const serialize = <
  K extends string,
  S extends string | null | undefined,
>(
  obj: Record<K, S>,
) => {
  const strippedObj = // null and undefined values will be stripped out.
    // This also acts as an API to remove values from the query string
    // queryString = '?foo=bar&baz=boo'
    // serialize({ ...deserialize(queryString), foo: null }) // = '?baz=boo'
    pickBy<typeof obj, typeof obj>(v => v != null, obj);
  return isEmpty(strippedObj)
    ? ''
    : `?${unsafeKeys(strippedObj)
        .map(key => {
          const value = strippedObj[key];
          return typeof value !== 'string'
            ? ''
            : `${key}=${encodeURIComponent(value)}`;
        })
        .filter(s => s !== '')
        .join('&')}`;
};
export const deserialize = (qs: string): QueryStringObject => {
  if (isEmpty(qs) || !qs.startsWith('?') || qs === '?') {
    return {};
  }
  const queryParamString = unwrap(qs.split('?')[1]);
  return queryParamString.split('&').reduce((acc, keyValue) => {
    const [key, value] = keyValue.split('=');
    return {
      ...acc,
      [unwrap(key)]: decodeURIComponent(value || ''),
    };
  }, {});
};

export const getQueryParams = (): QueryStringObject => {
  // If there are duplicate keys the last occurrence will be used.
  const locationSearchString = window.location.search;
  if (locationSearchString === '') {
    return {};
  }
  return deserialize(locationSearchString);
};

export const serializeBool = (b: boolean): QueryStringBoolean =>
  b.toString() as QueryStringBoolean;

export const deserializeBool = (s: string): boolean => s === 'true';

export const serializeInt = (n: number): QueryStringInt => n.toString();

export const deserializeInt = (s: string): number => parseInt(s, 10);

export const serializeBoolToPositiveFlag = (b: boolean) => (b ? 'Y' : '');

export const deserializeBoolFromPositiveFlag = (s: string): boolean =>
  s === 'Y';
