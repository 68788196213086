/*globals
define:false
 */
define([
    'can/view/mustache',
    'helpers/hybrid_app_util/hybrid_app_util'
],
function(can, HybridAppUtil) {
    'use strict';

    /**
     * @name ifHybridApp
     * @example {{#ifHybridApp}}You are on mobile{{/ifHybridApp}}
     */
    can.Mustache.registerHelper('ifHybridApp', function(options) {
        if (HybridAppUtil.isHybridApp()) {
            return options.fn(this);
        }
        else {
            return options.inverse(this);
        }
    });
});
