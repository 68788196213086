/*globals
    App:false,
    define:false,
    moment:false
 */
define([
    'raven-js',
    'can/view/mustache',
    'app/env/env',
    'helpers/momentjs/momentjs'
], function(Raven, can) {
    'use strict';

    /**
     * @name showDateTime
     * @example {{showDateTime date}} -> Jan 15, 9:00 am
     * @example {{showDateTime date "MMM DD"}} -> Jan 15
     *
     * Formats the given Date object.
     */
    can.Mustache.registerHelper('showDateTime', function(date, outputFormat, options) {
        if (arguments.length === 2) {
            options = outputFormat;
            outputFormat = undefined;
        }
        var dateResolved = can.Mustache.resolve(date);
        if (!dateResolved) {
            return '';
        }
        // Converts to a moment instance. If input is a String, it will
        // attempt to parse ISO 8601 format.
        var dateMoment = moment(dateResolved);
        if (!outputFormat) {
            outputFormat = App.Env.DATETIME_FORMAT.MOMENT_JS;
        }

        var prettyDate = dateMoment.format(outputFormat);

        // Capture invalid dates. This is to help identify
        // potential issues where the date may be incorrect.
        if ((/\b(1969|1970)\b/).exec(prettyDate)) {
            var dateObject = new Date(date);
            Raven.captureMessage(
                'We have an occurrence of the zero/null date.',
                {
                    getTime: dateObject.getTime(),
                    getTimezoneOffset: dateObject.getTimezoneOffset(),
                    toString: dateObject.toString(),
                    toISOString: dateObject.toISOString()
                });
        }

        return prettyDate;
    });


    /**
     * Convert seconds into human readable format like:
     * xx hours xx minutes, or xx minutes
     *
     * @param {Number} totalSeconds
     * @return {String}
     */
    can.Mustache.registerHelper('showPreciseDuration', function(seconds, options) {

        var secondsResolved = can.Mustache.resolve(seconds);
        if (!secondsResolved) {
            return '-';
        }
        var mDuration = moment.duration(secondsResolved, "seconds");

        var pluralize = function (num, word) {
            return num + ' ' + word + (num === 1 ? '' : 's');
        };
        var result = [];
        var delimiter = ' ';

        if (mDuration.years()) {
            result.push(pluralize(mDuration.years(), 'year'));
        }
        if (mDuration.months()) {
            result.push(pluralize(mDuration.months(), 'month'));
        }
        if (mDuration.days()) {
            result.push(pluralize(mDuration.days(), 'day'));
        }
        if (mDuration.hours()) {
            result.push(pluralize(mDuration.hours(), 'hour'));
        }
        if (mDuration.minutes()) {
            result.push(pluralize(mDuration.minutes(), 'minute'));
        }
        if (mDuration.seconds()) {
            result.push(pluralize(mDuration.seconds(), 'second'));
        }

        return result.join(delimiter);
    });




    /**
     * Convert seconds into human readable format like:
     * xx days xx hours
     *
     * @param {Number} totalSeconds
     * @return {String}
     */
    can.Mustache.registerHelper('showDuration', function(seconds, options) {

        var secondsResolved = can.Mustache.resolve(seconds);
        if (!secondsResolved) {
            return '-';
        }
        var mDuration = moment.duration(secondsResolved, "seconds");

        return mDuration.humanize();
    });
});
