/*globals
    define:false
 */
define(
    ['can/view/mustache'],
    function(can) {
        'use strict';

        /**
		 * @name cycle
         * @example {{cycle number value1 value2 value3 ...}}
         * @example {{cycle @index "odd" "even"}}
         *
         * Similar to the Django `cycle` templatetag, its output rotates between
         * values as index increases. See tests for examples.
         */
        can.Mustache.registerHelper('cycle', function(index) {
            var values = can.makeArray(arguments).slice(1, -1);
            var indexClipped = can.Mustache.resolve(index) % values.length;
            return can.Mustache.resolve(values[indexClipped]);
        });
    });
