/* eslint-disable dot-notation, no-useless-concat */

// TODO: This file uses CommonJS module syntax purely for compatibility with CanJS.

const jQuery = require('jquery/dist/jquery');

// This next line is confusing.
// We're essentially prepending the following import statement into the `jquery-migrate` module.
//   const jQuery = require('jquery/dist/jquery');
// This is needed to allow the `jquery-migrate` module to be executed in strict mode.
// See: https://github.com/webpack/imports-loader
// eslint-disable-next-line import/no-webpack-loader-syntax
require('imports-loader?jQuery=jquery/dist/jquery!jquery-migrate');

// If we use window.jQuery Webpack's ProvidePlugin will replace rewrite this.
// @ts-expect-error
window['jQuery' + ''] = jQuery;
// If we use window.$ Webpack's ProvidePlugin will replace rewrite this.
// @ts-expect-error
window['$' + ''] = jQuery;

module.exports = jQuery;
