/*globals
	define:false,
	require:false
 */
define([
    'jquery',
    'can/util/jquery',
    'mustache!./wistia_video.mustache',
    'load-script',
    'can/component',
    'can/map/define',
    'can/map/delegate'
    // ('//fast.wistia.com/assets/external/E-v1.js' is lazily-loaded.)
], function($, can, videoPlayerTemplate, load) {
    'use strict';

    /**
     * Component that plays videos from Wistia.
     *
     * This component relies on the Wistia Player API.
     * See: http://wistia.com/doc/player-api
     *
     * @name uc-wistia-video
     * @class UIComponents.WistiaVideo
     * @example <uc-wistia-video video-id"{wistiaVideoId}" play="true"></uc-wistia-video>
     */
    can.Component.extend('UIComponents.WistiaVideo',{
        /**
         * Render a normal div tag into uc-wistia-video tag
         * @param elm
         * @param options
         */
        render: function(elm, options) {
            var context = can.extend({}, options || {}, elm.data());
            context.aspect169 = '16-9' === context.aspectRatio;

            var template =
                can.view.mustache(
                    '<uc-wistia-video video-id="{{videoId}}" ' +
                    '{{^aspect169}}aspect169="false" {{/aspect169}}' +
                    '{{#aspect169}}aspect169="true" {{/aspect169}}' +
                    'loop="false" play="false"></uc-wistia-video>');
            var frag = template(context);
            elm.append(frag);
        }
    },
    {
        tag: 'uc-wistia-video',
        template: videoPlayerTemplate,
        scope: {
            define: {
                aspect169: {
                    type: 'boolean',
                    value: true
                },
                loop: {
                    type: 'boolean',
                    value: false
                },
                play: {
                    // this means auto play!
                    type: 'boolean',
                    value: false
                },
                videoId: {
                    type: 'string',
                    value: null
                }
            },
            isPlayingInLoop: function () {
                return this.attr('loop');
            }
        },
        events: {
            /**
             * BEWARE! `_wistiaEmbed` might be null if the Wistia API is still
             * loading, or if it has failed to load.
             */
            _wistiaEmbed: null,
            inserted: function() {
                var self = this;

                // Windows 8.1 app webview incompatibility workaround
                // See card #9966
                if (window.navigator.mimeTypes === undefined) {
                    window.navigator.mimeTypes = [];
                }

                // Lazily load the Wistia Javascript API. Why?
                //
                // 1) Clients won't have to download ~100Kb if a page doesn't
                //    use Wistia videos.
                //
                // 2) When a client is unable to download this dependency,
                //    it will no longer cause the whole page to
                //    catastrophically fail. This happened plenty of times:
                //    Trello #7800, #7811, #8144.
                //
                load('//fast.wistia.com/assets/external/E-v1.js', function () {
                    var Wistia = window.Wistia;
                    var videoId = self.scope.attr('videoId');
                    var container = self.element.find('.wistia_embed');
                    // Instantiate the Wistia player using the Javascript API.
                    self._wistiaEmbed = Wistia.embed(videoId, {
                        container: container.attr('id'),
                        controlsVisibleOnLoad: false,
                        fullscreenButton: true,
                        // Cause the player to fill to container's width, and set height based on the
                        // video's aspect ratio, see http://wistia.com/doc/responsive-design#video_foam
                        videoFoam: true,
                        //flash/html5 only. Doesn't work on mobile. When true, the video plays as soon as it's ready.
                        autoPlay: self.scope.attr('play'),
                        plugin: {
                            "socialbar-v1": {
                                on: false
                            }
                        }
                    });
                    self._wistiaEmbed.bind("end", function() {
                        self.element.trigger('wistia-end');
                    });
                });
            },
            removed: function() {
                var wistiaEmbed = this._wistiaEmbed;
                if (wistiaEmbed) {
                    wistiaEmbed.unbind();
                    // The Wistia Player needs to do its own cleanup.
                    wistiaEmbed.remove();
                }
                this._wistiaEmbed = null;
            },
            '{scope} play': function(scope, event, newValue) {
                if (!this._wistiaEmbed) {
                    // Wistia API not loaded yet - that's fine, it will have
                    // autoPlay set appropriately when loaded.
                }
                else if (newValue) {
                    this._wistiaEmbed.play();
                }
                else {
                    this._wistiaEmbed.pause();
                }
            }
        }
    });
});
