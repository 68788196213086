/*globals
    App:false,
    define:false
 */
define([
    'can/view/mustache', 
    'app/env/env'
], function(can) {
    'use strict';

    /**
     * `{{trans text}}`
     *
     * Similar to the Django `trans` text. Translates text to the current language.
     */
    // TODO: Implement
    can.Mustache.registerHelper('trans', function(text) {
        return text;
    });
});
