/*globals
    define:false
 */
define([
    'jquery', 
    'app/env/env', 
    'can/view/mustache'
], function($) {
    'use strict';

    $.ajaxPrefilter(function(options, originalOptions, jqXHR) {
        jqXHR.fail(function() {
            if (jqXHR.status == 403) {
                var response;
                try {
                    response = JSON.parse(jqXHR.responseText);
                } catch (e) {}

                // If the user is not authorized to make an AJAX call (and they are not on the login or signup page), then
                // refresh the page - The backend will then redirect them to the appropriate login page.
                if (response && response.detail === 'Authentication credentials were not provided.' &&
                    location.pathname !== App.Env.SIGNUP_URL && location.pathname !== App.Env.LOGIN_URL) {

                    location.reload();
                }
            }
        });
    });
});
