/** This file must be compatible with IE8 so the browser update warning is displayed. */

define(['jquery'], function ($) {
   'use strict';

    window.Helpers = window.Helpers || {};
    /**
     * @param {String} ns
     * @param {Object<String, Object>} content
     */
    return window.Helpers.Namespace = function (ns, content) {
        var _ns = window;
        $.each(ns.split('.'), function (index, __ns) {
            _ns = (_ns[__ns] = _ns[__ns] || {});
        });
        $.extend(true, _ns, content);
        return _ns;
    };
});
