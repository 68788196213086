/*global
    document:false,
    gettext:false,
    define:false
*/
define([
    'jquery', 
    'helpers/i18n/i18n', 
    'css!./css/jquery.reject.css', 
    './resources/jquery.reject'
], function ($) {
    "use strict";
    $(document).ready(function ($) {
        var options = {
            reject:{
                msie5:true,
                msie6:true,
                msie7:true,
                msie8:true,
                msie9:true,
                firefox3:true
                //all: true

                // // Rejection flags for specific browsers
                //            all: true // Covers Everything (Nothing blocked)
                //            msie5: true,
                //            msie6: true // Covers MSIE 5-6 (Blocked by default)
                /*
                 * Possibilities are endless...
                 *
                 * // MSIE Flags (Global, 5-8)
                 * msie, msie5, msie6, msie7, msie8,
                 * // Firefox Flags (Global, 1-3)
                 * firefox, firefox1, firefox2, firefox3,
                 * // Konqueror Flags (Global, 1-3)
                 * konqueror, konqueror1, konqueror2, konqueror3,
                 * // Chrome Flags (Global, 1-4)
                 * chrome, chrome1, chrome2, chrome3, chrome4,
                 * // Safari Flags (Global, 1-4)
                 * safari, safari2, safari3, safari4,
                 * // Opera Flags (Global, 7-10)
                 * opera, opera7, opera8, opera9, opera10,
                 * // Rendering Engines (Gecko, Webkit, Trident, KHTML, Presto)
                 * gecko, webkit, trident, khtml, presto,
                 * // Operating Systems (Win, Mac, Linux, Solaris, iPhone)
                 * win, mac, linux, solaris, iphone,
                 * unknown // Unknown covers everything else
                 */
            },
            display:['chrome', 'firefox'], // What browsers to display and their order (default set
            // below)
            browserShow:true, // Should the browser options be shown?
            browserInfo:{ // Settings for which browsers to display
                firefox:{
                    text:'Firefox', // Text below the icon
                    url:'http://www.mozilla.com/firefox/' // URL For icon/text link
                },
//                safari:{
//                    text:'Safari 5.1',
//                    url:'http://www.apple.com/safari/download/'
//                }
//          opera:{
//            text:'Opera 11',
//            url:'http://www.opera.com/download/'
//          },
                chrome:{
                    text:'Chrome',
                    url:'http://www.google.com/chrome/'
                }
//                    msie:{
//                        text:'Internet Explorer 10',
//                        url:'http://windows.microsoft.com/en-au/internet-explorer/ie-10-worldwide-languages/'
//                    }
//          gcf:{
//            text:'Google Chrome Frame',
//            url:'http://code.google.com/chrome/chromeframe/',
//            // This browser option will only be displayed for MSIE
//            allow:{ all:false, msie:true }
//          }
            },

            // Header of pop-up window
            header:gettext('Did you know that your Internet Browser is out of date?'),
            // Paragraph 1
            paragraph1:gettext('Your browser is out of date, and may not be compatible with our ' +
                'website. A list of the most popular web browsers can be found below.'),
            // Paragraph 2
            paragraph2:gettext('Just click on the icons to get to the download page'),
            close:true, // Allow closing of window
            // Message displayed below closing link
            closeMessage:gettext('By closing this window you acknowledge that your experience ' +
                'on this website may be degraded'),
            closeLink:gettext('Close This Window'), // Text for closing link
            closeURL:'#', // Close URL
            closeESC:true, // Allow closing of window with esc key

            // If cookies should be used to remmember if the window was closed
            // See cookieSettings for more options
            closeCookie:true,
            // Cookie settings are only used if closeCookie is true
            cookieSettings:{
                // Path for the cookie to be saved on
                // Should be root domain in most cases
                path:'/',
                // Expiration Date (in seconds)
                // 0 (default) means it ends with the current session
                expires: 24 * 60 * 60      // 1 day

            },
            // Usage of imagePath has been deprecated, path has moved into CSS
            // imagePath:'./images/', // Path where images are located
            overlayBgColor:'#000', // Background color for overlay
            overlayOpacity:0.8, // Background transparency (0-1)

            // Fade in time on open ('slow','medium','fast' or integer in ms)
            fadeInTime:'fast',
            // Fade out time on close ('slow','medium','fast' or integer in ms)
            fadeOutTime:'fast',
            analytics:false
        };

        $.reject(options); // Default Settings
    });
});