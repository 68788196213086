/*globals
    define:false
 */
define(
    ['can/view/mustache'],
    function(can) {
        'use strict';

        /**
		 * @name addOffset
         * @example {{addOffset @index 1}}
         *
         * Return the sum of the two arguments.
         */
        can.Mustache.registerHelper('addOffset', function(index, offset, options) {
            var indexResolved = can.Mustache.resolve(index);
            var offsetResolved = can.Mustache.resolve(offset);
            return indexResolved + offsetResolved;
        });
    });
