/*globals
    define:false
 */
define(['jquery'], function($) {
    'use strict';

    /*
     The regex is used to make sure that the host of the target URL matches
     the host of the current page (to check that it is an internal
     link within the same domain).
     This event only affects HTML <a> tags which reference actual URLs.
     It does not handle form submissions via submit buttons - This should
     be the responsibility of the page hosting the form.
     */
    var noFadeClass = 'no-fade-link';
    var hashRouteRegex = new RegExp('^https?://' + location.host + location.pathname + '.*#');
    var localURLRegex = new RegExp('^https?://' + location.host);

    var fadeOverlay;

    $(document.body).on('click', 'a', function(e) {
        var target = e.currentTarget;
        var targetURL = target.href;
        var isSpecialKey = e.metaKey || e.ctrlKey || e.altKey || e.shiftKey;
        var isHashRoute = hashRouteRegex.test(targetURL);
        var hyperlinkTarget = target.target;
        var isCurrentFrame = !hyperlinkTarget || hyperlinkTarget == '_self' ||
            hyperlinkTarget == '_parent' || hyperlinkTarget == '_top';
        var hasNoFadeClass = $(target).hasClass(noFadeClass);

        if (targetURL && localURLRegex.test(targetURL) && !isSpecialKey &&
            !isHashRoute && isCurrentFrame && !hasNoFadeClass) {

            var overlayZ = 1000;
            var addZ = 20;

            fadeOverlay = $('<div class="js-fade-overlay"></div>');
            fadeOverlay.css({
                'position': 'fixed',
                'top': 0,
                'left': 0,
                'width': '100%',
                'height': '100%',
                'background-color': '#fff',
                'opacity': 0,
                'z-index': overlayZ
            });

            var body = $('body');
            body.find('.fixed-header').css('z-index', overlayZ + addZ);
            body.append(fadeOverlay);
            fadeOverlay.fadeTo(100, 0.5).delay(1000).queue(function () {
              fadeOverlay.remove();
              $(this).dequeue();
            });
        }
    });

    // Workaround for Firefox & Safari: When using the Back button sometimes
    // the page state is restored with the transition overlay left on the page.
    // This encourages the browser to instead reload the page.
    if (window.onunload == null) {
        window.onunload = function(){};
    }

});
