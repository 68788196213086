/*globals
    define:false
 */
define(
    ['can/view/mustache'],
    function(can) {
        'use strict';

        /**
		 * @name ifAny
         * @example {{#ifAny expression1 expression2...}}...{{else}}...{{/ifAny}}
		 *
         * If any of the arguments is truthy, displays the nested block.
		 * Otherwise displays the `{{else}}` block.
         */
        can.Mustache.registerHelper('ifAny', function() {
            var conditions = can.makeArray(arguments);
            var options = conditions.pop();
			var length = conditions.length;
            for (var i=0; i<length; i++) {
                if (can.Mustache.resolve(conditions[i])) {
                    return options.fn(this);
                }
            }
            return options.inverse(this);
        });
    });
