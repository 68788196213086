/*globals
    define:false
 */
define(
    ['jquery'],

    function($) {
        'use strict';

        /**
         * This shim for **all** AJAX requests forces the content-type to
         * ``application/json``, and auto-encodes the supplied data as JSON.
         *
         * ``django-rest-framework`` can't correctly read POST request data
         * formatted to the content-type ``application/x-www-form-urlencoded``,
         * when the data is not a flat data structure, so this shim allows us
         * to send non-flat data to Django.
         *
         * A simple example:
         *
         *   $.ajax({
         *      url: '/api/job',
         *      type: 'POST',
         *      data: {
         *          'name': 'Work the banana stand',
         *          'person': {
         *              'name': 'George Michael'
         *          }
         *      }
         *   });
         *
         * With the default content-type ``application/x-www-form-urlencoded``,
         * the request's POST data will look like this:
         *
         *   name=Work the banana stand
         *   person[name]=George Michael
         *
         * Unfortunately, ``django-rest-framework`` will interpret this as a
         * flat dict:
         *
         *   {
         *      'name': 'Work the banana stand',
         *      'person[name]': 'George Michael'
         *   }
         *
         * The workaround here is to send ``application/json`` encoded data,
         * which is correctly interpreted by Django and
         * ``django-rest-framework``.
         *
         */
        var contentTypeJson = 'application/json; charset=utf8';
        var contentTypeDefault = 'application/x-www-form-urlencoded; charset=UTF-8';
        $.ajaxSetup({
            contentType: contentTypeJson,
            // To disable the param serialisation that jQuery would otherwise
            // do.
            processData: false,
            // To enable our custom data serialisation.
            processDjangoData: true
        });
        $.ajaxPrefilter(function(options) {
            if (!options.processDjangoData) {
                return;
            }
            // Does the content-type start with 'application/json'?
            var isContentTypeJson = (
                options.contentType.slice(0, contentTypeJson.length) === contentTypeJson
            );
            // Restrict to requests that send data in the request body.
            var type = options.type.toLowerCase();
            var isPostRequest = (
                type === 'post' || type === 'put' || type === 'delete'
            );
            // Restrict this behavior to requests aimed at our own backend.
            var localOrigin = (document.location.protocol + '//' + document.location.host);
            var isLocalRequest = (
                // The URL is a relative-to-domain URL.
                (options.url.indexOf('://') === -1) ||
                // OR the URL is prefixed with the current domain.
                (options.url.slice(0, localOrigin.length) === localOrigin)
            );
            if (isContentTypeJson && isPostRequest && isLocalRequest) {
                options.data = $.toJSON(options.data);
            }
            else {
                // Serialize params if not already a string, for GET requests to
                // work as per the default of `processData` set to true.
                if (options.data && typeof options.data !== "string" ) {
                    // Restore the default Content-Type header; here we are
                    // assuming that jQuery's default behavior is desired, since
                    // there were no overrides to the ajax options, and data is
                    // still not serialized to a string.
                    if (options.contentType === contentTypeJson) {
                        options.contentType = contentTypeDefault;
                        options.data = jQuery.param(options.data, options.traditional);
                    }
                }
            }
            // Ensure we don't serialise more than once, if the request is
            // recursive, e.g. in the case of retries.
            options.processDjangoData = false;
        });
    });
